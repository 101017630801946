import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import MyPager from "../components/MyPager";
import MyCalculation from "../utils/MyCalculation";


export default class SimplifiedChinese {
    constructor() {
        console.log("SimplifiedChinese: constructor");
}
  static content =  {
    site: {
      loading_message: "下载中...",
      ie_message: <div className="lead">看起来您正在使用 Internet Explorer（IE）。 我们的网站最适合以 Chrome 浏览，并且将来可能无法支持 IE。 请考虑使用 Chrome，Edge 或 Firefox 以获得最佳效果。<br /><br />
      您可以在此处下载 Chrome： <a
                  target="_blank"
                  href="https://www.google.com/chrome/"
                  rel="noopener noreferrer"
                >
                  Chrome
                </a>
      </div>,
      share_button_label: {
        share: "分享此页面",
        hide: "隐藏分享工具"
      }
    },
    benefitHarmPage: {
      banner_image_source: "/image/FLIKR+Yale+Rosen+CC2.0.jpg",
      banner_title: "肺癌筛检(检查)",
      title: "您应该了解的有关肺癌筛检的知识",
      benefit_harm_content: {
        benefit: {
          title: "筛检有什么好处？",
          content:
            "对于有资格进行筛检并决定接受筛检的人，及早发现癌症的机会更高。 尽早发现癌症通常意味着可以提供更成功的治疗方法。 最近的一项研究表明：在六年半之后，符合筛检条件的人中，接受电脑断层扫描（CT）筛检的人比未接受筛检的人死于肺癌的可能性要低 20％。"
        },
        harm: {
          title: "筛检的危害是什么？",
          content: (
            <div>
              <p>
              肺癌筛检仅有助于发现已经存在的癌症。 它不能预防癌症。 预防癌症的最佳方法是停止吸烟（如果您尚未这样做的话）。与传统的胸腔电脑断层扫描相比，用于肺癌筛检的电脑断层扫描使用的辐射剂量更低：最多可减少 90％。肺癌筛检的辐射剂量等于您日常环境中约 6 个月的辐射量。因此，要从一次成人检查中发展出致命肺癌的额外风险是极低的。对总辐射剂量感兴趣的话，可以访问&nbsp;
                <a
                  target="_blank"
                  href="http://www.xrayrisk.com/calculator/calculator-normal-studies.php?id=68"
                  rel="noopener noreferrer"
                >
                  这个网站
                </a>
                。筛检的其他潜在危害包括：假警讯，过度治疗，更多测试和侵入性医疗程序。
              </p>
            </div>
          )
        }
      },
      sample_list: [
        {
          title: "假警讯",
          content: (
            <div>
              <p>
              筛检的原理是通过发现肺结节来进行，其中一些结节是癌症，但是在低剂量电脑断层扫描（CT）中检测到的大多数结节<strong>不是</strong>癌症。肺结节是圆形或椭圆形的斑点。根据当前的结节管理指南，肺癌的假阳性率很低，约为10-12％ (
                <a
                  target="_blank"
                  href="https://pubmed.ncbi.nlm.nih.gov/29632062/"
                  rel="noopener noreferrer"
                >
                  McKee et al.
                </a>
                ). 
                错误的假阳性结果可能会引起焦虑。 但是，若能了解到『CT筛检所发现的大多数肺结节<strong>不</strong>代表癌症』，有助于大多数人在等待后续检查时缓解焦虑。
              </p>
            </div>
          )
        },
        {
          title: "过度治疗（和过度诊断）",
          content: (
            <div>
              <p>
              有时候，筛检会发现被断定为癌症的结节，但这些结节其实不会造成任何问题。 这样的诊断可能会导致后续不必要的治疗，例如手术，化学疗法和放射治疗，也就是所谓的过度治疗。 过度治疗可能会导致副作用，例如使您感到不适。
              </p>
            </div>
          )
        },
        {
          title: "持续筛检",
          content: (
            <div>
              <p>
              为了确认结节不是癌症，通常建议进行后续扫描以监测变化。 您的医生会建议每间隔一段适当的时间来进行随访，监测被检测出的肺结节。 通常会建议每 6 个月至 12 个月进行一次后续追踪。
              </p>
            </div>
          )
        },
        {
          title: "侵入性检查",
          content: (
            <div>
              <p>
              有时候，得到肺癌筛检筛结果后需要进行进一步的其他检查，其中包括侵入性检查（切片检查或是手术）。 这些测试是有风险的，即便检查后确认并非癌症，还是有可能对身体造成伤害。侵入性手术可能导致并发症，轻微的并发症如出血和感染，严重的并发症如肺萎缩甚至危及性命。
              </p>
            </div>
          )
        }
      ],
      more_info: (
        <p>
          有关更多详细的资讯，您可以造访国家癌症研究所（National Cancer Institute）网站整理的
          <a
            href={"https://www.cancer.gov/types/lung/research/nlst-qa"}
            target="_blank"
            rel="noopener noreferrer"
          >
            {"常见问题集"}
          </a>，该网页对全国性的肺部筛检试验结果有详细的说明，或是 <a
            href={"https://screenyourlungs.org/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            {"screenyourlungs.org"}
          </a> 网站。
        </p>
      ),
      image_info: {
        head: "Image: ",
        src: "https://www.flickr.com/photos/pulmonary_pathology/",
        text: "Yale Rosen/Flickr. CC BY-SA 2.0."
      }
    },
    homePage: {
      banner_title: "肺癌筛检(检查)",
      banner_content: "我应该接受筛检吗？",
      banner_button_label: "点击了解更多",
      banner_button_link: "/SimplifiedChinese/how-is-screening-done",
      two_column_title: "我们能帮助你。",
      two_column_sub_title:
        "要决定是否进行电脑断层扫描来筛检肺癌并不容易。在这里，医生提供了最新资讯，以帮助您做出明智的选择。",
      //"Deciding whether or not to go through lung cancer CT screening is not easy. We have put together a road map of what you should know based on where you are in this important decision.",
      two_column_content: {
        first: {
          title: "谁有资格进行肺癌筛检？",
          sub_title: (
            <div>
              <p>
              美国预防服务工作小组 (The US Preventive Services Task Force) 最近将肺癌筛检的资格指南更新为以下内容：
              </p>
              <ul>
                <li>年龄在 50 至 80 岁之间</li>
                <li>在过去 15 年内曾经吸烟</li>
                <li>
                  20 包年 (pack-years) (参照{" "}
                  <Link
                    to={"/SimplifiedChinese/pack-year-calculator"}
                    
                    // not working
                    //to={this.getRouteByPageAndLanguage("CalculatorPage", "English")}
                    style={{ width: "100%", textDecoration: "none" }}
                    data-on="click"
                    data-event-category="HomePage"
                    data-event-action={"/SimplifiedChinese/pack-year-calculator"}
                  >
                    包年计算器
                  </Link>
                  )
                </li>
              </ul>
              <p>
              联邦老人医疗保险以及联邦医疗保险的给付指南目前的条件（截至2022年2月10日）是下列项目:
              </p>
              <ul>
                <li>50-77岁</li>
                <li>过去 15 年内吸烟</li>
                <li>
                20 包年 (参阅{" "}
                  <Link
                    to={"/SimplifiedChinese/pack-year-calculator"}
                    
                    // not working
                    //to={this.getRouteByPageAndLanguage("CalculatorPage", "English")}
                    style={{ width: "100%", textDecoration: "none" }}
                    data-on="click"
                    data-event-category="HomePage"
                    data-event-action={"/SimplifiedChinese/pack-year-calculator"}
                  >
                    包年计算器
                  </Link>
                  )
                </li>
              </ul>
            
            </div>
          ),
          image: "/image/questioningDude.jpg",
          button_label: "了解更多",
          link: "/SimplifiedChinese/how-is-screening-done"
        },
        second: {
          title: "肺癌风险计算器",
          sub_title:
            "您想知道是否应该接受筛检吗？使用我们的计算器查看您的个人化肺癌风险。",
          image: "/image/SIScalculator.jpg",
          button_label: "使用肺癌风险计算器",
          link: "/SimplifiedChinese/lung-cancer-risk-calculator"
        }
      },
      time_navigation: [
        {
          title: "1. 为什么要做肺癌筛检(检查)？",
          items: [
            {
              title: "我为什么要考虑这件事？",
              content: (
                <div>
                  <p>
                    筛检可以帮助患者在早期发现肺癌，若能及时接受治疗，成功率也较高。如果不进行筛检，通常会在肺癌晚期才发现。{" "}
                  </p>
                  <div className="float-right">
                    <MyPager
                      link={"/SimplifiedChinese/how-is-screening-done"}
                      label={"了解更多"}
                      arrowDirection={"right"}
                    ></MyPager>
                  </div>
                </div>
              )
            },
            {
              title: "肺癌的警讯是什么？",
              content: (
                <p>
                  下列症状都与肺癌有关，例如疼痛、非预期的体重减轻、咳血，或者咳嗽不止，若你是吸烟者更要特别注意这些症状。
                  {" "}
                  <strong>
                  但是肺癌筛检并不是要提供给已有症状的患者
                  </strong>
                  。如果您有任何让您感到担忧的症状，请和您的医生讨论。
                </p>
              )
            },
            {
              title: "什么是肺癌电脑断层筛检？",
              content: (
                <div>
                  <p>
                    肺癌筛检包含胸部电脑断层扫描（CT)。进行电脑断层扫描时，您需要静躺着穿过一台环型机器，过程中您的胸腔影像会透过X光以及电脑拍摄、纪录下来。{" "}
                  </p>
                  <div className="float-right">
                    <MyPager
                      link={"/SimplifiedChinese/how-is-screening-done"}
                      label={"了解更多"}
                      arrowDirection={"right"}
                    ></MyPager>
                  </div>
                </div>
              )
            }
          ]
        },
        {
          title: "2. 花费",
          items: [
            {
              title: "花费",
              content: (
                <div>
                  <p>
                  如果您没有保险，那么初步的肺癌筛检可能要自费100到400美金。您可以点击下方按钮前往保险页面，以查看您的健康保险是否有涵盖肺癌筛检。
                  </p>
                  <p>
                    <div className="float-right">
                      <MyPager
                        link={"/SimplifiedChinese/coverage"}
                        label={"了解更多"}
                        arrowDirection={"right"}
                      ></MyPager>
                    </div>
                  </p>
                </div>
              )
            },
            {
              title: "我可以到哪里做筛检？",

              content: (
                <div>
                  <p>
                    建议您到「美国放射学会」认证的机构进行肺癌筛检。 点击&nbsp;
                    <a
                      target="_blank"
                      href="https://www.acr.org/Clinical-Resources/Lung-Cancer-Screening-Resources/LCS-Locator-Tool"
                      rel="noopener noreferrer"
                    >
                      此处
                    </a>
                    &nbsp;查看通过认证机构列表.
                  </p>
                </div>
              )
            }
          ]
        },
        {
          title: "3. 其他需要考量的事项",
          items: [
            {
              title: "肺癌筛检不是一次性的事情",
              content: (
                <div>
                  <p>
                    肺癌筛检并非一次性的，是需要定期筛检的。只要您还符合筛检资格，就需要每年安排一次筛检，才能最大化筛检的效益。另外，筛检后若发现肺结节，则需要在三至六个月内以电脑断层扫描进行后续追踪。{" "}
                  </p>
                </div>
              )
            },

            {
              title: "关于潜在的风险",
              content: (
                <div>
                  <p>
                    您需要考虑到肺癌筛检有可能会出现伪阳性的结果，也可能会导致过度治疗的决策。
                  </p>
                  <p>
                    <div className="float-right">
                      <MyPager
                        link={"/SimplifiedChinese/benefits-and-harms-screening"}
                        label={"了解更多"}
                        arrowDirection={"right"}
                      ></MyPager>
                    </div>
                  </p>
                </div>
              )
            },
            {
              title:
                "除了吸烟之外，其他的肺癌风险因子",
              content: (
                <div>
                  <p>
                    至今，虽然吸烟仍然是导致肺癌最大的因素，但「氡」和职业暴露（例如石棉，砷，二氧化矽，镉）也是肺癌的风险因子。
                  </p>
                  <p>
                    <div className="float-right">
                      <MyPager
                        link={"/SimplifiedChinese/causes-of-lung-cancer"}
                        label={"了解更多"}
                        arrowDirection={"right"}
                      ></MyPager>
                    </div>
                  </p>
                </div>
              )
            }
          ]
        }
      ]
    },
    causePage: {
      banner_image_source: "/image/smoke.jpg",
      banner_title: "肺癌筛检(检查)",
      cause_info: {
        title: "肺癌及其成因",
        causes: [
          <p>
            肺癌始于肺气道中遭受永久性损伤的细胞。这种损伤会导致细胞异常不受控制地生长，并可能扩散到身体的其他部位（转移）。肺癌是全球癌症相关死亡的主要原因。
          </p>,
          <p>
            肺癌的成因有很多，其中最重要和最广为人知的风险因素是<strong>吸烟</strong>。
          </p>
        ]
      },
      cause_chart: {
        title: "肺癌成因的比例预估*",
        src: "/image/attributable_risks_tiff2_English.png",
        text:
          "*Alberg AJ, Samet JM. Epidemiology of Lung Cancer. Chest. 2003; 123:21-49."
      },
      risk_info: {
        title: "其他已知的可能肺癌致病成因有：",
        risks: [
          "长期接触氡气（比如矿工等职业长期接触的一种无色、无味的气体，可积聚在一些家庭的地下室）；",
          "于工作时接触石棉、砷和某些类型的铬和二氧化矽；",
          "由医生做出的慢性阻塞性肺病 (Chronic Obstructive Pulmonary Disease, COPD) 或肺气肿和/或慢性支气管炎的诊断；以及",
          "有家族肺癌病史。"
        ]
      }
    },
    comparePage: {
      image_info: {
        link: "/image/fruit.png",
        attribution: {
          link: "https://creativecommons.org/licenses/by-sa/2.0/",
          label: "MicroAssist/Flickr: CC BY-SA 2.0"
        }
      },
      title:
        "肺癌 CT 筛检与其他癌症筛检相比如何？",
      sub_title: "你知道吗？",
      content:
        "与其他常见的癌症筛检方法（如乳腺癌乳房 X 光筛检）相比，肺癌 CT 筛检在减少高危人群肺癌死亡方面的效果至少与乳房 X 光筛检在预防乳腺癌死亡方面一样有效。",
      comparison_table: {
        column_title_list: [
          <div>
            <center>
              Type of cancer screening<sup>1</sup>
            </center>
          </div>,
          <div>
            <center>Years of annual screens</center>
          </div>,
          <div>
            <center>
              Number of cancer deaths prevented<sup>2</sup>
            </center>
          </div>
        ],
        row_list: [
          {
            title: "Lung",
            cell_list: [3, 3.1]
          },
          {
            title: "Colorectal",
            cell_list: [5, 2.8]
          },
          {
            title: "Breast",
            cell_list: ["", ""]
          },
          {
            title: "Age 60-69",
            cell_list: [10, 2.6]
          },
          {
            title: "Age 50-59",
            cell_list: [10, 0.8]
          }
        ]
      },
      foot_note_list: [
        <div>
          <sup>1</sup>此处与肺癌、结肠直肠癌和乳腺癌筛检相对应的程序分别是：低剂量 CT 扫描、带有粪便潜血检测的乙状结肠镜检查和乳房 X 光摄影。
        </div>,
        <div>
          <sup>2</sup>这表明每 1000 人接受筛检的死亡人数。
        </div>
      ]
    },
    disclaimerPage: {
      title: "免责声明",
      content:
        "本决策帮助中提供的资讯仅用于一般健康资讯。 风险计算器只能根据您提供的回答来估计您的风险。 即使我们尝试提供可用的最佳估计值，但对于个人而言，这些数字可能并不准确。 举例说，可能存在未测量的特定风险因素。 因此，本网站上提供的资讯不能替代健康专业人士的医疗建议。 如果您担心肺癌或患肺癌的风险，请向您的医生或其他医疗保健提供者咨询。 本网站包含指向第三方运营的其他网站的链接，我们无法保证任何第三方网站的陈述或其内容的准确性。"
    },
    howPage: {
      title: "肺癌筛检(检查)须知",
      banner_image_source: "/image/um-ct-shoot.jpg",
      banner_title: "肺癌筛检(检查)",
      sample_list: [
        {
          title: "问题一：什么是肺癌筛检？",
          content: (
            <div>
              <p>
              肺癌筛检使用低辐射剂量电脑断层扫描（LDCT），即低剂量的 CT 扫描，以发现肺结节，而这些结节其中一些可能是癌症。 参与筛检的人，因为较容易及早发现癌症并作相对应的处理，可以降低死于肺癌的机会。
              </p>
              <p>
              通常，肺癌筛检不是一次性的检查，而是在医生指引下进行的一个筛检过程，其中包括检验结果的后续追踪，以及在您仍符合资格的情况下每年进行持续性的筛检。
              </p>
            </div>
          )
        },
        {
          title: "问题二：谁有资格进行筛检？",
          content: (
            <div>
              <p>
              并不是每个人都适合进行肺癌筛检。 根据美国预防服务工作队 (The United States Preventive Services Task Force) 的说法，您必须符合以下条件才能进行筛检：
              </p>
              <ul>
                <li>年龄介于 50 至 80 岁之间，</li>
                <li>在过去 15 年内有吸烟，并且</li>
                <li>吸烟量在 20 包年 (pack-years) 或以上。</li>
              </ul>
              <p>
                包年 (pack-year) 是用于描述您一生中抽了多少支香烟的一种单位，一包以 20 支香烟来计算。 如果您在过去 20 年中每天吸一包烟，或者在过去 10 年中每天吸两包烟，则您有 20 个包年 (pack-years)。
                点选{" "}
                <Link
                  to={"/SimplifiedChinese/pack-year-calculator"}
                  style={{ width: "100%", textDecoration: "none" }}
                  data-on="click"
                  data-event-category="HowPage"
                  data-event-action={"/SimplifiedChinese/pack-year-calculator"}
                >
                  这个连结
                </Link>{" "}
                来计算您有多少包年。
              </p>
              <p>
              注意：持有联邦老人医疗保险 (Medicare) 的高危险群，且其年龄介于 55 至 77 岁之间的人可以接受筛检并由保险补助。
              </p>
            </div>
          )
        },
        {
          title: "问题三：筛检是怎么进行的？",
          content: (
            <div>
              <p>
                以下说明改编自{" "}
                <a
                  href="https://www.radiologyinfo.org/en/info.cfm?pg=screening-lung#part_three"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  美国放射学学会 (American College of Radiology)
                </a>
                ：
              </p>
              <p>
              在进行低剂量电脑断层扫描(CT)筛检的过程中，您将平躺在检验台上。因为需要保持正确的姿势，在检查过程中会用枕头和绑带帮助您保持静止，并要求您将手臂举过头顶。 接下来，检验台会移动以便将您放在扫描仪中正确的起始位置。 扫描过程中，会请您屏住呼吸 5 到 10 秒钟，检验台会持续移动让您通过扫描仪。
              </p>
            </div>
          )
        },
        {
          title: "问题四：进行第一次筛检后有什么后续的步骤吗?",
          content: (
            <div>
              <p>
                如果在电脑断层扫描 (CT) 扫描中发现肺结节，您的医生可能会建议进行后续 CT 扫描。后续的扫描通常会在 3 - 6 个月后进行来检查结节是否有增长。肺结节是圆形或椭圆形的斑点。在极少数的情况下，如果结节确实生长或可能引起健康疑虑，您的医生可能会建议使用 {" "}
                <a
                  href="https://medlineplus.gov/ency/article/007342.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  正电子 (PET) 扫描
                </a>{" "}
                或活体组织切片（取出一小块结节）进行进一步检查。
              </p>
              <p>
              但是，由于发现的大多数肺结节不是癌症，您可能只需要像其他年度健康检查一样在一年后返回继续筛检过程。 请记住，肺癌筛检不是一个单一的测试：它是一个必须在您的医生的指导下正确完成的过程。
              </p>
            </div>
          )
        },
        {
          title:
            "问题五：用于肺癌筛检的电脑断层 (CT) 扫描会让我暴露在多少辐射之下？",
          content: (
            <div>
              <p>
                用于肺癌筛检的 CT 扫描使用的辐射比传统胸部 CT 扫描少 90%。近似的有效辐射剂量为 1.5 毫西弗 (mSv)，相当于大约 6 个月的「正常」背景辐射，风险非常低。事实上，目前并没有发现在背景辐射和癌症风险之间有相关性。 从一次检查中患上致命肺癌的额外风险低到难以衡量，估计的机率在 100,000 分之一到 10,000 分之一之间。想了解更多有关辐射暴露的资讯，您可以查阅{" "}
                <a
                  href="https://www.radiologyinfo.org/en/info.cfm?pg=safety-xray"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  此处
                </a>
                {" "}或是{" "}
                <a
                  href="http://www.xrayrisk.com/calculator/calculator-normal-studies.php?id=68"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  此处
                </a>
                。
              </p>
            </div>
          )
        },
        {
          title: "问题六：我可以在哪里接受筛检？",
          content: (
            <div>
              <p>
              您应当在经认可的机构接受筛检。 你可以用{" "}
                <a
                  href="
                  https://www.acr.org/Clinical-Resources/Lung-Cancer-Screening-Resources/LCS-Locator-Tool"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  这个网站
                </a>
                {" "}找到一个离你近的筛检机构。
              </p>
            </div>
          )
        }
      ]
    },
    riskPage: {
      banner_image_source: "/image/gsh-sa-balloons.jpg",
      banner_title: "降低您罹患肺癌的风险",
      title:
        "想要降低罹患肺癌的风险，最好的方法就是戒烟。",

      description: (
        <p>
          若您有抽烟的话，LDCT断层扫描虽然可以降低您死于肺癌的机率, 但<strong>更为</strong>有效的方法就是戒烟。这虽然困难，但仍是可以做到的。想要成功戒烟，您必须克服对于尼古丁的渴瘾以及根深蒂固的抽烟习惯。许多人以「压力」为由，而不尝试戒烟。但事实上，压力本就是日常生活的一部分，若只是等待压力自动「消失」，而不做有益健康的事情，那最终只会导向失败的结果。我们必须学习各种因应压力的方法，先从和医生讨论您的压力来源开始。外面有许多资源可以帮助您戒烟，有些资源可以免费或降价为您提供美国食品药物管理局（FDA）批准的戒烟协助。以下提供一些资源给您参考：
        </p>
      ),
      state_resource_list: [
        { state: "选择一个州", links: [] },
        ,
        {
          state: "阿拉巴马州",
          links: [
            {
              label:
                "阿拉巴马州公共卫生部烟草防治",
              link: "http://www.adph.org/tobacco/Default.asp?id=779"
            }
          ]
        },

        {
          state: "阿拉斯加州",
          links: [
            {
              label: "无烟阿拉斯加",
              link:
                "http://dhss.alaska.gov/dph/Chronic/Pages/Tobacco/TobaccoFreeAlaska/default.aspx"
            }
          ]
        },

        {
          state: "亚利桑那州",
          links: [
            {
              label: "ASHLine-亚利桑那州的吸烟者帮助专线",
              link: "https://ashline.org/"
            },
            {
              label: "无烟亚利桑那",
              link: "http://azdhs.gov/tobaccofreeaz/"
            }

            
          ]
        },

        {
          state: "阿肯色州",
          links: [
            {
              label: "阿肯色州戒烟资源",
              link:
                "http://www.healthy.arkansas.gov/programs-services/topics/tobacco-cessation-resources"
            },
            {
              label: "消灭吸烟",
              link: "http://www.stampoutsmoking.com/get-help-to-quit/"
            }
          ]
        },

        {
          state: "加利福尼亚州",
          links: [
            {
              label: "加州烟草控制计划",
              link:
                "https://www.cdph.ca.gov/Programs/CCDPHP/DCDIC/CTCB/Pages/CessationServicesAndResources.aspx"
            },
            {
              label: "加州吸烟者帮助专线",
              link: "https://www.nobutts.org/"
            }
          ]
        },

        {
          state: "科罗拉多州",
          links: [
            {
              label: "科罗拉多州公共卫生与环境部",
              link: "https://www.colorado.gov/pacific/cdphe/quit-tobacco"
            },
            {
              label: "无烟科罗拉多",
              link: "http://www.tobaccofreeco.org/"
            }
          ]
        },

        {
          state: "康涅狄格州",
          links: [
            {
              label: "康涅狄格州公共卫生部",
              link:
                "http://www.ct.gov/dph/cwp/view.asp?a=3137&q=388064&dphNav=|&dphNav_GID=1841"
            }
          ]
        },

        {
          state: "特拉华州",
          links: [
            {
              label: "特拉华州烟草防治计划",
              link: "http://dhss.delaware.gov/dhss/dph/dpc/tobacco.html"
            }
          ]
        },

        {
          state: "佛罗里达州",
          links: [
            {
              label: "无烟佛罗里达",
              link:
                "http://www.floridahealth.gov/programs-and-services/prevention/tobacco-free-florida/index.html"
            }
          ]
        },

        {
          state: "乔治亚州",
          links: [
            {
              label: "乔治亚州公共卫生部-准备戒烟",
              link: "https://dph.georgia.gov/ready-quit"
            }
          ]
        },

        {
          state: "夏威夷州",
          links: [
            {
              label: "夏威夷州卫生部烟草控制",
              link: "http://health.hawaii.gov/tobacco/home/cessation/"
            },
            {
              label: "夏威夷州烟草戒烟专线",
              link: "http://hawaiiquitline.org"
            }
          ]
        },

        {
          state: "爱达荷州",
          links: [
            {
              label: "爱达荷州烟草防治计划",
              link:
                "http://www.healthandwelfare.idaho.gov/Health/TobaccoPreventionandControl/tabid/324/Default.aspx"
            },
            { label: "过滤计划", link: "http://projectfilter.org/" }
          ]
        },

        {
          state: "伊利诺州",
          links: [
            {
              label: "伊利诺州烟草戒烟专线",
              link: "http://quityes.org/home.html"
            }
          ]
        },

        {
          state: "印第安纳州",
          links: [
            {
              label: "印第安纳州卫生署",
              link: "http://www.in.gov/isdh/tpc/2353.htm"
            },
            {
              label: "即刻戒烟印第安纳州",
              link: "http://www.quitnowindiana.com/"
            }
          ]
        },

        {
          state: "爱荷华州",
          links: [
            {
              label: "爱荷华州戒烟专线",
              link: "https://iowa.quitlogix.org/"
            }
          ]
        },

        {
          state: "堪萨斯州",
          links: [
            {
              label: "堪萨斯州卫生与环境部",
              link: "http://www.kdheks.gov/tobacco/cessation.html"
            },
            { label: "堪萨斯州戒烟!", link: "http://www.ksquit.org/" }
          ]
        },

        {
          state: "肯塔基州",
          links: [
            {
              label: "肯塔基州烟草防治和戒烟计划",
              link: "http://chfs.ky.gov/dph/mch/hp/tobacco.htm"
            }
          ]
        },

        {
          state: "路易斯安那州",
          links: [
            {
              label: "一起戒烟，路易斯安那州",
              link: "http://www.quitwithusla.org/"
            }
          ]
        },

        {
          state: "缅因州",
          links: [
            {
              label: "缅因州无烟合伙",
              link: "http://www.tobaccofreemaine.org/"
            }
          ]
        },

        {
          state: "马里兰州",
          links: [
          { 
            label: "吸烟到此为止-马里兰州的戒烟资源", 
            link: "https://smokingstopshere.com/" 
          },
          { 
            label: "马里兰州戒烟", 
            link: "http://mdquit.org/" 
          }
            ]
        },

        {
          state: "麻萨诸塞州",
          links: [
            {
              label: "让吸烟成为过去",
              link: "http://makesmokinghistory.org/"
            }
          ]
        },

        {
          state: "密西根州",
          links: [
            {
              label: "密西根州社区卫生部",
              link:
                "http://www.michigan.gov/mdhhs/0,5885,7-339-71550_2955_2973_53244---,00.html"
            },
            {
              label: "密西根大学烟草处遇计划",
              link:
                "https://hr.umich.edu/benefits-wellness/health-well-being/mhealthy/faculty-staff-well-being/alcohol-tobacco-programs/tobacco-consultation-service"
            }
          ]
        },

        {
          state: "明尼苏达州",
          links: [
            {
              label: "明尼苏达州卫生部",
              link: "http://www.health.state.mn.us/divs/hpcd/tpc/quit.html"
            },
            {
              label: "戒烟计划服务。没有批判只有协助。",
              link: "https://www.quitplan.com/"
            }
          ]
        },

        {
          state: "密西西比州",
          links: [
            {
              label: "密西西比州烟草控制办公室",
              link:
                "http://www.msdh.state.ms.us/msdhsite/_static/43,0,94.html#community"
            },
            {
              label: "密西西比州戒烟专线",
              link: "http://www.quitlinems.com/"
            }
          ]
        },

        {
          state: "密苏里州",
          links: [
            {
              label: "密苏里州卫生与老年服务部",
              link:
                "http://health.mo.gov/living/wellness/tobacco/smokingandtobacco/tobaccocontrol.php"
            }
          ]
        },
        {
          state: "蒙大拿州",
          links: [
            {
              label: "蒙大拿州烟草使用防治计划",
              link: "http://dphhs.mt.gov/publichealth/mtupp"
            },
            {
              label: "蒙大拿州戒烟专线",
              link: "https://montana.quitlogix.org/"
            }
          ]
        },
        {
          state: "内布拉斯加州",
          links: [
            {
              label: "无烟内布拉斯加州",
              link:
                "http://dhhs.ne.gov/publichealth/Pages/smokefree_sf_ql.aspx"
            }
          ]
        },

        {
          state: "内华达州",
          links: [
            {
              label: "内华达州烟草防治计划",
              link:
                "http://dpbh.nv.gov/Programs/TPC/Tobacco_Prevention_and_Control_-_Home/"
            },
            {
              label: "内华达州烟草预防联盟",
              link:
                "http://www.tobaccofreenv.org/tobacco-information/quitting-smoking"
            }
          ]
        },

        {
          state: "新罕布夏州",
          links: [
            {
              label: "新罕布夏州烟草防治计划",
              link: "http://www.dhhs.state.nh.us/dphs/tobacco/index.htm"
            },
            {
              label: "新罕布夏州烟草专线",
              link: "http://trytostopnh.org/"
            }
          ]
        },
        {
          state: "纽泽西州",
          links: [
            {
              label: "纽泽西州烟草控制计划",
              link: "http://www.nj.gov/health/fhs/tobacco"
            },
            {
              label: "纽泽西州戒烟专线",
              link: "http://www.njquitline.org/treatment.html"
            }
          ]
        },

        {
          state: "新墨西哥州",
          links: [
            {
              label: "TUPAC：烟草使用的防治",
              link: "http://nmtupac.com/#home"
            }
          ]
        },

        {
          state: "纽约州",
          links: [
            {
              label: "纽约州吸烟者戒烟专线",
              link: "https://nysmokefree.com/"
            }
          ]
        },

        {
          state: "北卡罗来纳州",
          links: [
            {
              label: "北卡戒烟专线",
              link: "http://www.quitlinenc.com/"
            }
          ]
        },

        {
          state: "北达科他州",
          links: [
            {
              label: "北达科他州烟草防治计划",
              link: "http://www.ndhealth.gov/tobacco/"
            },
            {
              label: "北达科他州戒烟计划",
              link: "http://www.ndhealth.gov/tobacco/cessation.htm"
            }
          ]
        },

        {
          state: "俄亥俄州",
          links: [
            {
              label: "俄亥俄州卫生部烟草计划",
              link:
                "http://www.odh.ohio.gov/en/odhprograms/eh/quitnow/Tobacco/Cessation/Cessation"
            }
          ]
        },

        {
          state: "奥克拉荷马州",
          links: [
            {
              label: "奥克拉荷马州烟草使用预防和减少计划",
              link:
                "http://www.ok.gov/health/Wellness/Tobacco_Prevention/Programs/index.html"
            }
          ]
        },

        {
          state: "俄勒冈州",
          links: [
            {
              label: "俄勒冈州烟草预防和教育计划",
              link:
                "http://www.oregon.gov/oha/PH/PreventionWellness/TobaccoPrevention/GetHelpQuitting/Pages/oregonquitline.aspx"
            },
            {
              label: "无烟俄勒冈州",
              link: "https://www.quitnow.net/oregon/"
            }
          ]
        },

        {
          state: "宾夕法尼亚州",
          links: [
            {
              label: "宾夕法尼亚州烟草防治",
              link:
                "http://www.health.pa.gov/My%20Health/Healthy%20Living/Smoke%20Free%20Tobacco/Pages/default.aspx#.WbqmgfmGPcs"
            },
            {
              label: "宾州免费戒烟专线",
              link:
                "http://www.health.pa.gov/My%20Health/Healthy%20Living/Smoke%20Free%20Tobacco/Pages/PA-Free-Quitline.aspx#.WbrmPdOGORt"
            }
          ]
        },

        {
          state: "罗德岛州",
          links: [
            {
              label: "罗得岛州烟草控制计划",
              link: "http://www.health.ri.gov/programs/tobaccocontrol/"
            },
            { label: "即刻戒除 罗得岛州", link: "http://www.quitnowri.com/" }
          ]
        },
        {
          state: "南卡罗来纳州",
          links: [
            {
              label: "南卡罗来纳州戒烟",
              link: "http://www.scdhec.gov/Health/TobaccoCessation/"
            }
          ]
        },
        {
          state: "南达科他州",
          links: [
            {
              label: "南达科他州烟草防治计划",
              link: "http://doh.sd.gov/prevention/tobacco/"
            },
            {
              label: "南达科他州戒烟专线",
              link: "https://www.sdquitline.com/"
            }
          ]
        },
        {
          state: "田纳西州",
          links: [
            {
              label: "田纳西州戒烟专线",
              link: "http://health.state.tn.us/tobaccoquitline.htm"
            }
          ]
        },
        {
          state: "德克萨斯州",
          links: [
            {
              label: "德克萨斯州卫生服务部",
              link: "https://www.dshs.state.tx.us/tobacco/links.shtm"
            },
            { label: "（有误）Tobacco is foul", link: "http://www.ducktexas.org/" }
          ]
        },
        {
          state: "犹他州",
          links: [
            {
              label: "犹他州烟草防治计划",
              link: "http://www.tobaccofreeutah.org/"
            },
            {
              label: "戒烟之路",
              link: "http://waytoquit.org/"
            }
          ]
        },

        {
          state: "佛蒙特州",
          links: [
            {
              label: "佛蒙特州卫生部",
              link:
                "http://www.healthvermont.gov/prevent/tobacco/quit_smoking.aspx"
            },
            {
              label: "802 戒烟：佛蒙特州戒烟资源",
              link: "http://802quits.org/"
            }
          ]
        },
        {
          state: "维吉尼亚州",
          links: [
            {
              label: "维吉尼亚烟草使用控制计划",
              link: "http://www.vdh.virginia.gov/ofhs/prevention/tucp/"
            }
          ]
        },

        {
          state: "华盛顿州",
          links: [
            {
              label:
                "华盛顿州卫生烟草资源部",
              link:
                "http://www.doh.wa.gov/ForPublicHealthandHealthcareProviders/PublicHealthSystemResourcesandServices/LocalHealthResourcesandTools/Tobacco"
            }
          ]
        },

        {
          state: "西维吉尼亚州",
          links: [
            {
              label: "西维吉尼亚烟草预防资源",
              link:
                "http://www.dhhr.wv.gov/wvdtp/cessation/Pages/default.aspx"
            }
          ]
        },

        {
          state: "威斯康辛州",
          links: [
            {
              label: "威斯康辛州烟草防治计划",
              link: "https://www.dhs.wisconsin.gov/tobacco/index.htm"
            },
            {
              label: "威斯康辛州戒烟专线",
              link: "http://www.ctri.wisc.edu/quitline.html"
            }
          ]
        },

        {
          state: "怀俄明州",
          links: [
            {
              label:
                "怀俄明州卫生部烟草预防计划",
              link:
                "https://health.wyo.gov/publichealth/prevention/tobacco-prevention/wqtp/"
            }
          ]
        }
      ],
      resources_quit: [
        {
          link: "",
          label: <a href="tel:8008388917">Call 1-800-838-8917</a>
        },
        {
          link: "https://www.asiansmokersquitline.org/quitnow/tchinese/",
          label: "华语戒烟专线"
        },
        {
          link: "http://betobaccofree.hhs.gov/quit-now/index.html",
          label: "戒烟 BeTobaccoFree.gov (英文版)"
        },
        {
          link:
            "https://www.cdc.gov/tobacco/features/new-year/chinese/index.html",
          label: "美国疾病管制与预防中心"
        }
      ],
      resources_instructions:
        "请从下拉选单中选择一个州，以获得可用的资源。",
      radon_instructions:
        "「氡」是肺癌的另一个可预防的风险因子。 了解如何降低风险：",
      resources_radon: [
        {
          link:
            "https://www.epa.gov/sites/production/files/2016-12/documents/2016_a_citizens_guide_to_radon.pdf",
          label:
            "关于「氡」的市民指南-环境保护局编制 (英文版)"
        },
        {
          link:
            "https://www.atsdr.cdc.gov/toxfaqs/ToxFAQS_Foreign_Language_PDFs/tfacts145_chinese.pdf",
          label: "美国疾病管制与预防中心"
        }
      ]
    },
    packPage: {
      title: "抽烟量(包年)计算器",
      instruction: {
        normal:
          "「包年」这个量词用于描述您一生中抽了多少支香烟，一包等于 20 支香烟。如果您在过去 20 年中每天吸一包，或在过去 10 年中每天吸两包，那么您有 20 包年。换句话说，包年是衡量吸烟暴露的一种方法，它可以看出您吸烟的时间和吸烟量。目前，需要满足多项标准中「有 20 包年（或以上）」才会建议进行肺癌筛检(检查)。"
      },

      sub_title:
        "请回答以下两个问题，来算出您的「包年」。",
      submit_button_label: "计算",
      calculation_result_template:
        "您的吸烟暴露量为 x_number 包年。", // x_number will be replaced with calculation result, don't change it.
      pack_questions: {
        formYears: {
          label: "1. 请问您吸烟几年了？",
          placeholder: "多少年？"
        },
        formAmount: {
          label:
            "2. 平均而言，请问您每天抽几支烟？一包烟内有 20 支香烟。",
          placeholder: "每天抽几支烟？"
        }
      }
    },
    calculatePage: {
      banner_image_source: "/image/areachart2.png",
      banner_title: "您的肺癌风险",
      title:
        "如果您吸烟，降低患肺癌风险的最佳方法是戒烟。",

      instruction: {
        normal: (
          <div>
            <p>
            填写以下资讯来了解您是否属于{" "}
              <a
                target="_blank"
                href="https://www.uspreventiveservicestaskforce.org/Page/Document/RecommendationStatementFinal/lung-cancer-screening"
                rel="noopener noreferrer"
              >
                美国预防服务工作组
              </a>
              {" "}建议筛检(检查)的对象。这个计算机也会表明您从筛检中受益的程度。 这将帮助您更好地确定筛检的潜在益处是否大于危害。
            </p>
          </div>
        ),
        strong_text_list: ["* 表示必要的栏位"]
      },
      submit_button_label: "提交",
      default_select_label: " -- 选择 -- ",
      error_input_guidance:
        "您好，您填写的一项或多项内容可能有错误。 请向下卷动并检查。",
      tall_question: (
        <div>
          9. 您身高有多高？ &nbsp;
          <a
            target="_blank"
            //href="https://www.google.com/search?q=convert+centimeters+to+feet+and+inches&ie=utf-8&oe=utf-8"
            href="https://share.streamlit.io/should-i-screen/unit-converter/main?lan=zh-CN"
            rel="noopener noreferrer"
          >
            请帮我把公分换算成英尺和英寸。
          </a>
        </div>
      ),
      risk_questions: {
        formOld: {
          label: "1. 您今年几岁？*",
          placeholder: "",
          help_text:
            "问题 1 为必填的栏位，此计算机的有效年龄范围为 18 至 100 岁。"
        },
        formCurrentStatus: {
          label: "2. 您目前的吸烟状况如何？*",
          placeholder: "",
          options: [
            { label: "吸烟者", value: 1 },
            { label: "以前吸烟，现已戒烟", value: 0 },
            { label: "从不吸烟", value: 2 }
          ],
          help_text: "问题 2 为必填的栏位。"
        },
        formAgeLastSmoke: {
          label: "2.1. 您最后一次戒烟是在几岁？*",
          placeholder: "",
          options: [],
          help_text:
            "你已经表明你以前是吸烟者。 请确保您在问题 1 中填写的数字小于您当下的年龄。"
        },
        formYearsSmoking: {
          label: "3. 你总共抽了多少年的烟？*",
          placeholder: "",
          options: [],
          help_text:
            "问题 3 是必填的栏位。 请确保此值小于您当下的年龄，并且是一个数字。"
        },
        formCigarettesPerDay: {
          label:
            "4. 平均而言，您每天抽/吸多少支香烟？* 一包有 20 支香烟。",
          placeholder: "",
          options: [],
          help_text:
            "问题 4 是必填的栏位。 请确保您填写了 0 到 200 之间的数字。"
        },
        formGender: {
          label: "5. 你的性别是？",
          placeholder: "",
          options: [
            { label: "男性", value: "male" },
            { label: "女性", value: "female" }
          ]
        },
        formInsurance: {
          label: <div>6. 您是否在联邦老人医疗保险（Medicare）承保范围内？🏥</div>,
          placeholder: "",
          options: [
            { label: "是", value: "yes" },
            { label: "否", value: "no" },
            { label: "不知道/不适用", value: "unknown" }
          ]
        },
        formHighestGradeCompleted: {
          label:
            "7. 您完成的最高年级或学年(最高学历)是什么？",
          placeholder: "",
          options: [
            { label: "高中以下", value: 0 },
            { label: "高中毕业", value: 1 },
            { label: "高中毕业后的一些训练", value: 2 },
            { label: "一些大学经历", value: 3 },
            { label: "大学毕业", value: 4 },
            { label: "研究生或专业学位", value: 5 }
          ]
        },
        formRaceEthnicity: {
          label: "8. 您如何描述您的种族/民族？",
          placeholder: "",
          options: [
            { label: "白人", value: 0 },
            { label: "黑人", value: 1 },
            { label: "西班牙裔", value: 2 },
            { label: "亚裔", value: 3 },
            { label: "美洲印第安人或阿拉斯加原住民", value: 4 },
            { label: "夏威夷原住民或太平洋岛民",value: 5 }
          ]
        },
        formTallFeet: {
          label: "",
          placeholder: "",
          right_side_label: "英尺",
          options: [
            { label: "3", value: 3 },
            { label: "4", value: 4 },
            { label: "5", value: 5 },
            { label: "6", value: 6 },
            { label: "7", value: 7 },
            { label: "8", value: 8 },
            { label: "9", value: 9 }
          ]
        },
        formTallInch: {
          label: "",
          placeholder: "",
          right_side_label: "英寸",
          options: [
            { label: "0", value: 0 },
            { label: "1", value: 1 },
            { label: "2", value: 2 },
            { label: "3", value: 3 },
            { label: "4", value: 4 },
            { label: "5", value: 5 },
            { label: "6", value: 6 },
            { label: "7", value: 7 },
            { label: "8", value: 8 },
            { label: "9", value: 9 },
            { label: "10", value: 10 },
            { label: "11", value: 11 }
          ]
        },
        formWeight: {
          label: (
            <div>
              10. 您有多重？（磅）&nbsp;
              <a
                target="_blank"
                //href="https://www.google.com/search?q=convert+kilograms+to+pounds&ie=utf-8&oe=utf-8"
                href="https://share.streamlit.io/should-i-screen/unit-converter/main?lan=zh-CN"
                rel="noopener noreferrer"
              >
                请帮我把公斤换算成磅。
              </a>
            </div>
          ),
          placeholder: "",
          options: [],
          help_text:
            "请确保您在问题 9 中输入了 45 到 1000 之间的数字。这不是必填的栏位。如果您选择不填，计算器会带入一个预设值。"
        },
        formDoctorToldCancer: {
          label:
            "11. 您是否曾被医生告知您患有癌症？",
          placeholder: "",
          options: [
            { label: "是", value: 1 },
            { label: "否", value: 0 }
          ]
        },
        formFamilyHistory: {
          label: "12. 请问您是否有肺癌家族病史？",
          placeholder: "",
          options: [
            { label: "是", value: 1 },
            { label: "否", value: 0 }
          ]
        },
        formDoctorToldCOPD: {
          label:
            "13. 您的医生是否曾告知您患有慢性肺病，也称为慢性支气管炎或肺气肿（COPD）？ ",
          options: [
            { label: "是", value: 1 },
            { label: "否", value: 0 }
          ]
        }
      }
    },
    feedbackPage: {
      title: "感谢您的回馈！",
      submitted_message: "感谢您！",
      default_select_label: " -- 选择 -- ",
      submit_button_label: "提交",
      feedback_questions: {
        formGender: {
          label: "我是",
          placeholder: "",
          options: [
            { label: "女性", value: 0 },
            { label: "男性", value: 1 }
          ]
        },
        formSmokeStatus: {
          label: "我...",
          placeholder: "",
          options: [
            { label: "是一个吸烟者。", value: 1 },
            { label: "以前抽烟。", value: 0 },
            { label: "从来没有抽过烟。.", value: 2 }
          ]
        },
        formAge: {
          label: "您几岁？",
          placeholder: "50",
          options: []
        },
        formCalculatorResult: {
          label: "根据您在风险计算器中输入的内容, 您:",
          placeholder: "",
          options: [
            {
              label: "有资格接受筛检。",
              value: "Eligible to be screened"
            },
            {
              label: "没有资格接受筛检。",
              value: "Not eligible to be screened"
            },
            {
              label: "风险太低，无法准确计算。",
              value: "Risk was too low to be calculated accurately"
            }
          ]
        },
        formInformationReceiver: {
          label: "我正在为＿＿寻找资讯。",
          placeholder: "",
          options: [
            { label: "自己", value: "Myself" },
            { label: "我的伴侣", value: "My partner" },
            { label: "我的家人(们)", value: "My family member(s)" },
            { label: "我的朋友(们)", value: "My friend(s)" },
            { label: "我的病人(们)", value: "My patient(s)" }
          ]
        },
        formFindAllInformation: {
          label:
            "我找到了所有我正在寻找的有关肺癌筛检的资讯。",
          placeholder: "",
          options: [
            { label: "强烈反对", value: -2 },
            { label: "反对", value: -1 },
            { label: "不同意也不反对", value: 0 },
            { label: "同意", value: 1 },
            { label: "强烈同意", value: 2 }
          ]
        },
        formHelpConversationDoctor: {
          label:
            "我找到的资讯对跟我的医生讨论肺癌筛检有所帮助。",
          placeholder: "",
          options: [
            { label: "强烈反对", value: -2 },
            { label: "反对", value: -1 },
            { label: "不同意也不反对", value: 0 },
            { label: "同意", value: 1 },
            { label: "强烈同意", value: 2 }
          ]
        },
        formHelpDecision: {
          label:
            "这个网站对我做关于肺癌筛检的决定有所帮助。",
          placeholder: "",
          options: [
            { label: "强烈反对", value: -2 },
            { label: "反对", value: -1 },
            { label: "不同意也不反对", value: 0 },
            { label: "同意", value: 1 },
            { label: "强烈同意", value: 2 }
          ]
        },
        formInterestReason: {
          label: "我对肺癌筛检感兴趣是因为...",
          placeholder: "例如，我有癌症家族史。"
        },
        formComment: {
          label:
            "请随时提交您认为有助于我们改进网站的任何其他意见：",
          placeholder: ""
        }
      }
    },
    resultPage: {
      print_button_label: "列印",
      title: "肺癌筛检(检查):",
      sub_title: "我应该接受筛检吗?",
      risk_spectrum: {
        risk_statement: "您的肺癌风险：",
        low_risk_label: <span>&#60; 1%: 低风险</span>,
        intermediate_risk_label: <span>1-2%: 中等风险</span>,
        high_risk_label: <span>&#62; 2%: 高风险</span>
      },
      risk_confirmation:{
        label: "根据图表，我患肺癌的风险是：",
        placeholder: "",
        options: [
          { label: "高", value: "High" },
          { label: "中等", value: "Intermediate" },
          { label: "低", value: "Low" },
          { label: "不知道", value: "Don't know" }
        ]
      },
      comparison_chart_info_list:[
        {
          chartTitle:"未筛检",
          legendTitle: {
            "1000": "在1000个像你这样的人中",
            "100": "在100个像你这样的人中"
          },
          legendMap:{
            "die": "死于肺癌",
            "not die": "没有死于肺癌"
          }
        },
        {
          chartTitle:"筛检",
          legendTitle: {
            "1000": "在1000个像你这样的人中",
            "100": "在100个像你这样的人中"
          },
          legendMap:{
            "die": "死于肺癌",
            "fewer die": {
              "full": "因筛检而没有死于肺癌",
              "list": ["因筛检而没有",  "死于肺癌"]
            },
            "not die": "没有死于肺癌"
          }
        },
      ],
      benefit_confirmation:{
        //label: "After seeing the chart, I consider my benefits of getting screened being:",
        label: "快速确认：根据上述资讯，我认为筛检的好处，相对于我在未来6年患肺癌的风险，是",
        placeholder: "",
        options: [
          { label: "足够高", value: "High enough" },
          //{ label: "Intermediate", value: "Intermediate" },
          { label: "太低", value: "Too low" },
          { label: "不知道", value: "Don't know" }
        ]
      },
      risk_section: {
        title: "风险",
        statement_list: [
          "在 1000 名接受筛检的人中，约会有 120 人发现了并非癌症的肺结节。",
          "在 1000 人中约有 13 人由于非癌症的肺结节接受侵入性处置，如活检或手术。",
          "1000人中只有不到 1 人因侵入性手术而出现重大并发症。",
          "在筛检发现的肺癌中，不到十分一的肺癌会伤害你。这可能导致不必要的治疗和并发症。"
        ],
        foot_note:"* 这些过度诊断的肺癌病例如果没有被发现可能也不会造成伤害，并且患者可能不会感到任何症状。 然而，由于发现了这种癌症，患者可能会接受不必要的治疗，并可能出现并发症。 请注意，一旦发现癌症，就不可能知道它是否过度诊断。"
      },
      benefit_section: {
        title: "好处",
        statement_end:
          "与未接受筛检的人相比， 较少像你这样有接受筛检的人会死于肺癌。"
      },
      instructions: {
        beforeSubmission: [
          "现在，您应该了解什么是筛检，它可以做什么，不能做什么。如果您能让您的医生知道您对筛检的优缺点的理解跟想法，会很有帮助。请检查下面的哪个句子最能描述您对以下问题的回答。提醒您，这个问题并没有对或错的答案！",
          "在您看来，您从筛检中获得的个人利益是否让您觉得接受筛检正确的选择？在下面选择一个选项。"

        ],
        afterSubmission: [
          "记得跟您的医生谈谈您对肺癌筛检的看法！"
        ]
      },
      default_select_label: " -- 选择 -- ",
      submit_button_label: "提交",
      click_for_pdf_msg:
        "点选影像查看适合列印的版本。",
      getEligibilityStatement: function(sex, risk, eligibility, insurance, old, packYears, neverSmoked=false) {
        // sex: male, female
        // risk: 0.0 ~
        // eligibility: yes, no
        // insurance: yes, no, unknown
        // Old = -1;
        // yearsSmoking = -1;
        // cigarettesPerDay = -1;

        var result = "默认资格";

        if(neverSmoked){
          result = <div>肺癌 CT 筛检不建议那些从未吸烟的人进行。</div>;
          return result;
        }

        switch (eligibility) {
          case "yes":
            result = "";

            if (insurance == "yes") {
              // M
              result = (
                <div>
                  根据美国预防服务工作队的标准，您的年龄和吸烟史<strong>符合</strong>进行筛检的资格。
                </div>
              );
            } else {
              if( old > 77){
                result = (
                  <div>
                    根据美国预防服务工作队的标准，您的年龄和吸烟史<strong>符合</strong>进行筛检的资格。然而，联邦老人医疗保险只涵盖77岁以下的肺癌筛检。请参考本网站的
                    <Link
                      to={"/SimplifiedChinese/coverage"}
                      style={{ width: "100%" }}
                      data-on="click"
                      data-event-category="CoveragePage"
                      data-event-action={"/SimplifiedChinese/coverage"}
                    >
                      保险给付说明&nbsp;
                    </Link>
                    或联系您的保险公司，看看是否有其他选项，可能支付肺癌筛检的费用。
                  </div>
                );
              }
              else{
                result = (
                  <div>
                    根据美国预防服务工作队的标准，您的年龄和吸烟史<strong>符合</strong>进行筛检的资格。
                  </div>
                );
              }
              
            }
            break;
          case "no":
            result = (<p>
              根据美国预防服务工作队的标准，您的年龄和吸烟史<strong>不符合</strong>进行筛检的资格。
            </p>
            );
            break;
          default:
            result = "";
            break;
        }

        return result;
      },
      getChanceStatement(sex, risk, eligibility, insurance, old, packYears) {

        // sex: male, female
        // risk: 0.0 ~
        // eligibility: yes, no
        // medicare: yes, no, unknown
        var result = "Default Chance";

        console.log(`getChanceStatement(), eligibility: ${eligibility}  risk: ${risk}` );


        let  tailorMessage = "";

        let oldInRange = old >= 50 && old <=54;
        let packYearsInRange = packYears >= 20 && packYears <=29;

        if(oldInRange && packYearsInRange){
          tailorMessage = `由于您的年龄在50-54岁之间，吸烟史为20-29包年，根据美国预防服务工作队的最新标准，您符合肺癌筛检的资格。您有可能不在保险的给付范围，请跟您的医疗供给者询问确切的资讯。`;
        }
        else if(!oldInRange && packYearsInRange){
          tailorMessage = `由于您的吸烟史为20-29包年，根据美国预防服务工作队的最新标准，您符合肺癌筛检的资格。您有可能不在保险的给付范围，请跟您的医疗供给者询问确切的资讯。`;
        }
        else if(oldInRange && !packYearsInRange){
          tailorMessage = `由于您的年龄在50-54岁之间，根据美国预防服务工作队的最新标准，您符合肺癌筛检的资格。您有可能不在保险的给付范围，请跟您的医疗供给者询问确切的资讯。`;
        }

        

        if (risk == 0 ) {
          // smoker or not
          result = (
            <div>
              你在未来6年罹患肺癌的机率太低，无法准确估计。如果您担心患肺癌的风险，请咨询您的医生，因为他们会最了解您的情况。
            </div>
          );
        }
        else if (eligibility == "no") {
          if(risk >= 1.5){
            result = (<Fragment>
              <p>
                *然而，你在未来 6 年罹患肺癌的机率是 {risk}%，这超过了我们设定的门槛，表示您进行CT筛检是利大于弊的。你应该考虑和你的医生谈谈肺癌筛检是否适合你。
              </p>
              </Fragment>
            );
          }
          else{
            result = (
              <div>
                你在未来 6 年罹患肺癌的机率是 {risk}%。请与您的医生讨论筛检或不筛检的选项，因为他们最了解您的情况。
              </div>
            );
          }
          
        } else if (eligibility == "yes") {
          if(risk < 1.5){
            result = (<Fragment>
              <p>
                {tailorMessage}
              </p>
              <p>
              *然而，你在未来 6 年罹患肺癌的机率是 {risk}%，这比我们设定的门槛低，表示筛检的好处不够充足让您考虑 CT 筛检这个选择。你应该考虑和你的医生谈谈肺癌筛检是否仍然适合你。
              </p>

              </Fragment>
            );
          }
          else{
            result = (
              <div>
                <p>
                你在未来 6 年罹患肺癌的机率是 {risk}%。你应该考虑和你的医生谈谈肺癌筛检是否适合你。
                </p>          
                <p> 
                  {tailorMessage}
              </p>
              </div>
            );
          }
        }
        return result;
      },
      getExplanationStatement(sex, risk, eligibility, insurance) {
        // sex: male, female
        // risk: 0.0 ~
        // eligibility: yes, no
        // insurance: yes, no, unknown
        var result = "Default Explanation";

        // Math.round()

        // deal with it.

        // method 2: encapsulate into a function in  MyCalcuataion
        result = (
          <div>
            跟其他与你相似的人比较，如果你接受筛检，未来 6 年每 1000人中就有 {MyCalculation.calculateSavedByScreeningFromRisk(risk)} 人免去因肺癌而死。
          </div>
        );

        // method 1: calculate directly
        /*
        result = (
          <div>
            Compared to other people like you, there will be{" "}
            {Math.round(Math.round(risk * 10) - Math.round(risk * 10 * 0.8)) +
              " "}
            fewer deaths out of 1000 in the next 6 years if you get screened.
          </div>
        );
        */

        return result;
      },
      getBenefitTitle(sex, risk, eligibility, insurance) {
        // sex: male, female
        // risk: 0.0 ~
        // eligibility: yes, no
        // insurance: yes, no, unknown

        // we only need risk

        var result = "预设福利";

        result = "在 1000 个人中，有 " + 
          MyCalculation.calculateSavedByScreeningFromRisk(risk)  +
          " 人";

        return result;
      },
      reflection_questions: {
        formPersonalBenefit: {
          label: "",
          placeholder: "",
          options: [
            {
              label:
                "是的，我从筛检中获得的益处让我认为接受筛检是正确的选择。",
              value:
                "Yes. My benefit from screening is enough to justify getting screened."
            },
            {
              label:
                "也许，我从筛检中获得的益处让我认为接受筛检是大概是正确的选择。",
              value:
                "Maybe. My benefit from screening is probably enough to justify getting screened but I am not sure."
            },
            {
              label:
                "也许不会，我从筛检中获得的益处较小让我不确定接受筛检是不是个正确的选择。",
              value:
                "Maybe not. My benefit from screening is small and I'm not sure it justifies getting screened."
            },
            {
              label:
                "不会，我从筛检中获得的益处太小让我觉得接受筛检不算是正确的选择。",
              value:
                "No. My benefit from screening is too small to justify getting screened."
            }
          ]
        },
        formRecommended: {
          label: "我被推荐:",
          placeholder: "",
          options: [
            {
              label: "接受筛检",
              value: "Recommended to be screened"
            },
            {
              label: "不要接受筛检",
              value: "Not recommended to be screened"
            }
          ]
        },
        formRisk: {
          label: "我的风险是",
          placeholder: "1%"
        },
        formReason: {
          label: "我的原因是:",
          placeholder: ""
        }
      }
    },
    coveragePage: {
      banner_image_source: "/image/bannerImage_coveragePage.png",
      banner_title: "肺癌筛检(检查)的保险给付范围",
      title:
        "点选适用于您的选项，查看您的健康保险是否涵盖肺癌筛检(检查)。",
      //content: "The following information is from the American Lung Association.",
      condition_list: [
        {
          title: "雇主赞助的保险计划",
          introtext:
            "这是通过您的雇主、工会或其他专业协会为您提供的保险。如果您符合下列的条件，您有资格参加最初的肺癌筛检：",
          criteria_list: [
            "年龄在55至80岁之间",
            <div>
              有20包年的吸烟史 (点选&nbsp;
              <Link
                to={"/SimplifiedChinese/pack-year-calculator"}
                style={{ width: "100%", textDecoration: "none" }}
                data-on="click"
                data-event-category="CoveragePage"
                data-event-action={"/SimplifiedChinese/pack-year-calculator"}
              >
                连结&nbsp;
              </Link>
              算出你的包年)
            </div>,
            "在过去的15年里吸烟"
          ],

          note:
            "如果您的计划是平价医疗法案 (Affordable Care Act) 中允许的情况，它不需要涵盖肺癌筛检。请检查您的保险内容，并与您的保险公司核实确认。",
          question_answer_list: [
            {
              question: "需要分担费用吗？",
              answer:
                "不。如果您向您的医疗供给者寻求筛检，您不应该被收取挂号费(定额手续费)、共同承担额或自付额。"
            },
            {
              question: "有其他的费用吗?",
              answer:
                "可能有。请务必咨询您的保险公司，确认他们为该这个筛检手续提供哪些给付。"
            }
          ]
        },
        {
          title: "国家保险市场计划 (State insurance marketplace plan)",
          introtext:
            "这是您通过 healthcare.gov 或您州设置的市场/交换网站注册的计划。您有资格参加最初的肺癌筛检，如果您符合下列的条件:",
          criteria_list: [
            "are between 50 and 80 years old",
            <div>
              有30包年的吸烟史 (点选&nbsp;
              <Link
                to={"/SimplifiedChinese/pack-year-calculator"}
                style={{ width: "100%", textDecoration: "none" }}
                data-on="click"
                data-event-category="CoveragePage"
                data-event-action={"/SimplifiedChinese/pack-year-calculator"}
              >
                连结
              </Link>{" "}
              算出你的包年)
            </div>,
            "在过去的15年里吸烟"
          ],
          question_answer_list: [
            {
              question: "需要分担费用吗？",
              answer:
                "不。如果您向您的医疗供给者寻求筛检，您不应该被收取挂号费(定额手续费)、共同承担额或自付额。"
            },
            {
              question: "有其他的费用吗?",
              answer:
                "可能有。请务必咨询您的保险公司，确认他们为该这个筛检手续提供哪些给付。"
            }
          ]
        },
        {
          title: "联邦老人医疗保险 (Medicare)",
          introtext:
            "您有资格参加最初的肺癌筛检，如果您符合下列的条件:",
          criteria_list: [
            "年龄在50至77岁之间",
            <div>
              有20包年的吸烟史 (点选&nbsp;
              <Link
                to={"/SimplifiedChinese/pack-year-calculator"}
                style={{ width: "100%", textDecoration: "none" }}
                data-on="click"
                data-event-category="CoveragePage"
                data-event-action={"/SimplifiedChinese/pack-year-calculator"}
              >
                连结
              </Link>{" "}
              算出你的包年)
            </div>,
            "在过去的15年里吸烟",
            "没有肺癌的迹象或症状（例如疼痛、体重减轻、持续咳嗽）"
          ],
          question_answer_list: [
            {
              question: "需要分担费用吗？",
              answer:
                "不。如果您向您的医疗供给者寻求筛检，您不应该被收取挂号费(定额手续费)、共同承担额或自付额。"
            },
            {
              question: "有其他的费用吗?",
              answer:
                "可能有。请务必咨询您的保险公司，确认他们为该这个筛检手续提供哪些给付。"
            }
          ]
        },
        {
          title: "联邦医疗保险 (Medicaid)",
          introtext:
            "联邦医疗保险中肺癌筛检的覆盖面因州而异。我们建议您联系您的医疗服务提供者，并检查您的计划是否涵盖肺癌筛检。",

          criteria_list: [],
          note: "",
          question_answer_list: []
        },
        {
          title: "个人保险计划",
          introtext:
            "这是您直接从保险公司购买的保险。如果您符合下列的条件，您有资格参加最初的肺癌筛检:",
          criteria_list: [
            "年龄在50至80岁之间",
            <div>
              有20包年的吸烟史 (点选&nbsp;
              <Link
                to={"/SimplifiedChinese/pack-year-calculator"}
                style={{ width: "100%", textDecoration: "none" }}
                data-on="click"
                data-event-category="CoveragePage"
                data-event-action={"/SimplifiedChinese/pack-year-calculator"}
              >
                连结
              </Link>{" "}
              算出你的包年)
            </div>,
            "在过去的15年里吸烟"
          ],

          note:
            "如果您的计划是平价医疗法案 (Affordable Care Act) 中允许的情况，它不需要涵盖肺癌筛检。请检查您的保险内容，并与您的保险公司核实确认。",
          question_answer_list: [
            {
              question: "需要分担费用吗？",
              answer:
                "不。如果您向您的医疗供给者寻求筛检，您不应该被收取挂号费(定额手续费)、共同承担额或自付额。"
            },
            {
              question: "有其他的费用吗?",
              answer:
                "可能有。请务必咨询您的保险公司，确认他们为该这个筛检手续提供哪些给付。"
            }
          ]
        }
      ],
      citation_text: (
        <div>
          <p>
          这些资讯来自美国肺脏协会&nbsp;
            <a
              target="_blank"
              href="https://www.lung.org/assets/documents/lung-health/lung-cancer-insurance-chart.pdf"
              rel="noopener noreferrer"
            >
              美国肺脏协会
            </a>
            .
          </p>
        </div>
      )
    },
    savedPage: {
      title: "以下是您保存的资讯",
      item_unit: "片段",
      print_button_label: "列印"
    },
    publicationPage: {
      title: "研究出版品",
      publication_list: [
        {
          category: "journal",
          text: (
            <span>
              Yan Kwan Lau, Harihar Bhattarai, Tanner J Caverly, Pei-Yao Hung, 
              Evelyn Jimenez-Mendoza, Minal R Patel, Michele L Coté, 
              Douglas A Arenberg, Rafael Meza (2021).
              Lung Cancer Screening Knowledge, Perceptions, and Decision Making 
              Among African Americans in Detroit, Michigan.{" "}
              <i>American Journal of Preventive Medicine (AJPM)</i>, Volume
              60, Issue 1, Pages e1-e8
            </span>
          ),
          year: 2021,
          description:
            "我们在密西根州底特律东部测试了 shouldiscreen.com 是否有助于提高人们对肺癌风险和肺癌筛检(检查)的认识，并减少他们的决策冲突。",
          links: [
            {
              label: "Link",
              link: "https://pubmed.ncbi.nlm.nih.gov/33341184/"
            }
          ]
        },
        {
          category: "conference",
          text: (
            <span>
              Pei-Yao Hung, Yan Kwan Lau, Mark S. Ackerman, Rafael Meza
              (2019). Designing a Web-based Decision Aid for Individuals to
              Consider Lung Cancer Screening.{" "}
              <i>
                13th EAI International Conference on Pervasive Computing
                Technologies for Healthcare (PervasiveHealth)
              </i>
              , Trento, Italy, May 20-23. pp 51-60
            </span>
          ),
          year: 2019,
          description:
            "我们举办了参与式设计工作坊和焦点团体访谈，为决策辅助工具提供设计建议用来指引 shouldiscreen.com 的发展。",
          links: [
            {
              label: "Link",
              link: "https://doi.org/10.1145/3329189.3329210"
            }
          ]
        },
        {
          category: "journal",
          text: (
            <span>
              Yan Kwan Lau, Tanner J. Caverly, Pianpian Cao, Sarah T. Cherng,
              Mindy West, Charles Gaber, Douglas Arenberg, Rafael Meza (2015).
              Evaluation of a Personalized, Web-Based Decision Aid for Lung
              Cancer Screening.{" "}
              <i>American Journal of Preventive Medicine (AJPM)</i>, Volume
              49, Issue 6, Pages e125–e129
            </span>
          ),
          year: 2015,
          description:
            "我们测试了 shouldiscreen.com 是否有助于提高人们对肺癌风险和肺癌筛检的认识，并减少他们的决策冲突。",
          links: [
            {
              label: "Link",
              link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5544524/"
            }
          ]
        },
        {
          category: "journal",
          text: (
            <span>
              Yan Kwan Lau, Tanner J Caverly, Sarah T Cherng, Pianpian Cao,
              Mindy West, Douglas Arenberg, and Rafael Meza (2014).
              Development and Validation of a Personalized, Web-Based Decision
              Aid for Lung Cancer Screening Using Mixed Methods: A Study
              Protocol. <i>JMIR Research Protocol</i>
            </span>
          ),
          year: 2014,
          description:
            "这是一个规程，描述了 shouldiscreen.com 各个阶段的开发和研究计划。",
          links: [
            {
              label: "Link",
              link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4376198/"
            }
          ]
        }
      ]
    },
    teamPage: {
      title: "Should I Screen 团队",

      investigator_title: "计划主持人",

      investigator_list: [
        {
          name: "Rafael Meza, PhD",
          affiliation: [
            "密西根大学公共卫生学院流行病学系"
          ],
          link: "https://sph.umich.edu/faculty-profiles/meza-rafael.html"
        },
        {
          name: "Douglas Arenberg, MD",
          affiliation: [
            "密西根大学内科医学系"
          ],
          link: "https://uofmhealth.org/profile/286/douglas-allen-arenberg-md"
        },
        {
          name: "刘妍君 (Yan Kwan Lau) 博士，公共卫生硕士",
          affiliation: [
            "密西根大学公共卫生学院流行病学系"
          ],
          link: ""
        },
        {
          name: "Tanner Caverly, MD, MPH ",
          affiliation: [
            "安娜堡医疗保健系统退伍军人事务部临床管理研究中心；密西根大学内科医学系"
          ],
          link: "http://medicine.umich.edu/dept/lhs/tanner-caverly-md-mph"
        }
      ],

      research_development_title: "研发团队",

      research_development_list: [
        {
          name: "曹翩翩 (Pianpian Cao), 博士，公共卫生硕士",
          affiliation: [
            "密西根大学公共卫生学院流行病学系"
          ],
          link: "https://sph.umich.edu/epid/phdstudents/cao_pianpian.html"
        },
        {
          name: "程慈如 (Sarah Cherng) 博士，公共卫生硕士",
          affiliation: [
            "密西根大学公共卫生学院流行病学系"
          ],
          link: "http://www.sarahcherng.com/"
        },
        {
          name: "洪培尧 (Pei-Yao Hung) 博士，资讯科学硕士",
          affiliation: ["密西根大学资讯学院"],
          link: "https://peiyaoh.github.io/"
        },
        {
          name: "Mindy West, MPH",
          affiliation: [
            "密西根大学公共卫生学院流行病学系"
          ],
          link: ""
        }
      ],

      press_title: "新闻发布",

      press_resources_list: [
        {
          link:
            "https://www.healthcanal.com/cancers/lung-cancer/68111-tool-helps-patients-providers-decide-if-lung-cancer-screening-is-warranted.html",
          label:
            "Tool helps patients decide if lung cancer screening is warranted",
          source_label: "Health Canal"
        },
        {
          link:
            "http://www.reuters.com/article/us-health-screening-lung-cancer-idUSKCN0SF2LQ20151021#f3dQjQZuWa652jvR.97",
          label:
            "Web module helps patients decide about lung cancer screening",
          source_label: "Reuters"
        },
        {
          link:
            "https://www.nytimes.com/2015/05/12/health/on-medicare-and-assessing-the-value-of-lung-cancer-screening.html",
          label:
            "On Medicare and assessing the value of lung cancer screening",
          source_label: "New York Times"
        },
        {
          link:
            "https://news.umich.edu/tool-helps-patients-providers-decide-if-lung-cancer-screening-is-warranted/",
          label:
            "Tool helps patients, providers decide if lung cancer screening is warranted",
          source_label: "University of Michigan News"
         }
      ],
      founding_title: "资金来源",
      founding_content:
        "本工具的开发得到了密西根大学综合癌症中心的 Elizabeth A Crary 基金（2014-2015 年）和密西根大学罗杰尔癌症中心的癌症控制和人口科学研究计划：外展和健康差异补助金的支持。"
    },
    historyPage: {
      title: "shouldiscreen.com 的发展历程",
      description: <Fragment>
        <p>Shouldiscreen.com 是一个肺癌筛检(检查)决策辅助工具，它根据现有文献、多领域研究工作、健康专家的建议和用户的反馈不断设计、评估和重新设计。</p>
        <p>我们的目标是通过使本工具与当前对肺癌筛检的理解保持同步并通过以人为本的方法来不断改进它，以便人们可以轻松地增进对相关科学知识的了解和实践。</p>
        <p>在这里，我们概述了 shouldicreen.com 发展过程中的里程碑和更新列表。</p>
      </Fragment>,
      eventList:[
        // marker_color:  color from bulma.css: is-primary, is-link, is-info, is-success, is-warning, is-danger
        // markder_type: "", is-image, is-icon
        
        {
          label: "September 2017",
          description: "添加西班牙语内容并启用双语支援。",
          timestamp: "2017-09-01T00:00:00",
          marker_color: "is-danger",
          marker_type: "is-icon",
          // fontawesome
          marker_icon_type: "fa fa-flag"
        },
        {
          label: "July 2018",
          description: <Fragment>根据参与式设计和焦点团体访谈的观察和回馈，新增了关于保险给付的内容 (参阅 <a
            target="_blank"
            href="https://dl.acm.org/doi/10.1145/3329189.3329210"
            rel="noopener noreferrer"
          >
            连结
          </a>)。</Fragment>,
          timestamp: "2018-07-01T00:00:00",
          marker_color: "is-link"
        },
        {
          label: "September 2018",
          description: <Fragment>添加了“留存“功能，供人们在临床上跟医生进行共享决策的对话时能收集相关内容。</Fragment>,
          timestamp: "2018-09-01T00:00:00",
          marker_color: "is-link"
        },
        {
          label: "September 2018",
          description: <Fragment>添加了一个弹出式选单，供人们提供有关内容的回馈（实用/不实用)。</Fragment>,
          timestamp: "2018-09-15T00:00:00",
          marker_color: "is-primary"
        },
        {
          label: "December 2019",
          description: <Fragment>根据参与式设计和焦点团体访谈的观察，添加了问题导向的导航选单。 (参阅 <a
            target="_blank"
            href="https://dl.acm.org/doi/10.1145/3329189.3329210"
            rel="noopener noreferrer"
          >
            连结
          </a>).</Fragment>,
          timestamp: "2019-12-01T00:00:00",
          marker_color: "is-link"
        },
        {
          label: "May 2019",
          description: <Fragment>在 <i>13th EAI International Conference on Pervasive Computing Technologies for Healthcare</i> (PervasiveHealth 2019) 年度会议上发表我们为了 shouldiscreen.com 所进行的参与式设计和焦点团体访谈的研究成果。(参阅 <a
            target="_blank"
            href="https://dl.acm.org/doi/10.1145/3329189.3329210"
            rel="noopener noreferrer"
          >
            连结
          </a>).</Fragment>,
          timestamp: "2019-05-21T00:00:00",
          marker_color: "is-warning"
        },
        {
          label: "December 2019",
          description: <Fragment>更新了联邦老人医疗保险（Medicare）的筛选资格标准。</Fragment>,
          timestamp: "2019-12-12T00:00:00",
          marker_color: "is-primary"
        },
        {
          label: "January 2020",
          description: <Fragment>支援基于肺癌风险计算结果以及生活史资料的客制化资讯。</Fragment>,
          timestamp: "2020-01-01T00:00:00",
          marker_color: "is-primary"
        },
        {
          label: "August 2020",
          description: <Fragment>添加了多个视觉化呈现的选项，以帮助人们了解筛检的益处和风险。</Fragment>,
          timestamp: "2020-08-22T00:00:00",
          marker_color: "is-primary"
        },
        {
          label: "November 2020",
          description: <Fragment>修订关于假警讯造成危害的内容以反映美国预防服务工作小组（USPSTF） 证据审查 (2020) 中有关使用 LungRADS 资讯的发现。(参阅 <a
            target="_blank"
            href="https://www.uspreventiveservicestaskforce.org/uspstf/document/draft-evidence-review/lung-cancer-screening1"
            rel="noopener noreferrer"
          >
            连结
          </a>).</Fragment>,
          timestamp: "2020-11-21T00:00:00",
          marker_color: "is-primary"
        },
        {
          label: "January 2022",
          description: "添加中文内容并启用多语支援。",
          timestamp: "2022-01-29T00:00:00",
          marker_color: "is-danger",
          marker_type: "is-icon",
          // fontawesome
          marker_icon_type: "fa fa-flag"
        },
        /*
        {
          label: "February 2016",
          description: "Timeline content - Can include any HTML element",
          timestamp: "2016-02-01T00:00:00",
          marker_color: "is-warning",
          marker_type: "is-image",
          marker_image_size: "is-32x32",
          marker_image_source: "https://bulma.io/images/placeholders/32x32.png"
        },
        */
        
      ]
    },
    noPage: {
      title: "我应该接受筛检(检查)吗？: 未找到",
      not_found_message:
        "您不小心到达了这个页面，或者我们的网站有问题。 请点击以下连结返回首页。",
      take_me_home_label: "前往首页。",
      take_me_home_link: "/SimplifiedChinese/home"
    },
    footerComponent: {
      development_team_content: (
        <p>
          本网站是由 &nbsp;
          <Link
            to={"/SimplifiedChinese/development-team"}
            className="my-link"
            data-on="click"
            data-event-category="Navigation"
            data-event-action={"team"}
          >
            密西根大学的研究团队
          </Link>
          &nbsp; 使用 Tammemägi et al. (2013) 等人开发的风险预测模型来开发。&nbsp;
          <a
            target="_blank"
            href="https://www.nejm.org/doi/full/10.1056/NEJMoa1211776"
            rel="noopener noreferrer"
            className="my-link"
          >
            Selection criteria for lung-cancer screening
          </a>
          .&nbsp;<em>New England Journal of Medicine</em>, 368(8): 728-736,
          2013.
        </p>
      ),
      basis_content: (
        <p>
          请参阅{" "}
          <Link
            to={"/SimplifiedChinese/publications"}
            data-on="click"
            data-event-category="Navigation"
            data-event-action={"publications"}
            className="my-link"
          >
            出版物
          </Link>{" "}
          以获取有关此决策辅助工具的设计，开发和验证的研究论文 (同行审议文章)。
        </p>
      ),

      feedback_content: (
        <p>
           您的回馈对我们很重要，也很有价值。请&nbsp;
          <Link to={"/SimplifiedChinese/feedback"} className="my-link">填写此调查</Link>
          &nbsp;以帮助我们改善这个网站。&nbsp;
        </p>
      ),

      disclaimer_content: (
        <p>
          <Link to={"/SimplifiedChinese/disclaimer"} className="my-link">免责声明</Link>。 &nbsp;
          <em>
          本网站的书面内容在&nbsp; 
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://creativecommons.org/licenses/by-nc-sa/4.0/"
              className="my-link"
            >
              CC BY-NC-SA 4.0
            </a>
            下共享。
          </em>
          本网站上的所有图片均受版权保护，除非页面上另有说明，否则保留所有权利。
        </p>
      )
    },
    lastUpdateComponent: {
      message_head: "本网站的最新更新时间为 ",
      pointer_to_history: <Fragment>
        查阅<Link
                to={"/SimplifiedChinese/development-history"}
                data-on="click"
                data-event-category="UpdateComponent"
                data-event-action={"/SimplifiedChinese/development-history"}
                className="my-link"
              >
                更新纪录
              </Link>。
      </Fragment>
    },
    headerComponent: {
      title: "关于肺癌跟筛检(检查)",
      menu_title: "选单",
      route_in_menu_limit: 8, //9
      route_order_list: [
        {
          route: "/SimplifiedChinese/home",
          label: "首页",
          title: "我应该接受筛检吗? 肺癌筛检决策辅助",
          page: "HomePage",
          description: "肺癌筛检使用低剂量计算机断层扫描 (LDCT) 在早期发现肺癌。 以下是医生提供的信息，可帮助您做出决定。",
          hashTagList: ["shouldiscreen", "肺癌", "筛检", "健康"]
        },
        {
          route: "/SimplifiedChinese/how-is-screening-done",
          label: "什么是肺癌筛检?",
          title: "什么是肺癌筛检?",
          page: "HowPage",
          hashTagList: ["shouldiscreen", "肺癌", "筛检", "健康"]
        },
        {
          route: "/SimplifiedChinese/coverage",
          label: "保险给付",
          title: "肺癌筛检的保险给付",
          page: "CoveragePage",
          hashTagList: ["shouldiscreen", "肺癌", "筛检", "健康"]
        },
        {
          route: "/SimplifiedChinese/benefits-and-harms-screening",
          label: "筛检的利弊",
          title: "肺癌筛检的利弊",
          page: "BenefitHarmPage",
          hashTagList: ["shouldiscreen", "肺癌", "筛检", "健康"]
        },
        /*
        {
          route: "/SimplifiedChinese/compare-with-other-screening-tests",
          label: "Compared to other tests",
          title:
            "Comparison of lung cancer screening to other types of common cancer screening",
          page: "ComparePage",
          hashTagList: ["shouldiscreen", "肺癌", "筛检", "健康"]
        },
        */
        {
          route: "/SimplifiedChinese/causes-of-lung-cancer",
          label: "肺癌的成因",
          title: "肺癌的成因",
          page: "CausePage",
          hashTagList: ["shouldiscreen", "肺癌", "筛检", "健康"]
        },
        {
          route: "/SimplifiedChinese/pack-year-calculator",
          label: "抽烟量(包年)计算",
          title: "计算抽烟量(包年)",
          page: "PackPage",
          hashTagList: ["shouldiscreen", "肺癌", "筛检", "健康"]
        },
        {
          route: "/SimplifiedChinese/risk-reduction-lung-cancer",
          label: "降低患肺癌的风险",
          title: "降低患肺癌的风险",
          page: "RiskPage",
          hashTagList: ["shouldiscreen", "肺癌", "筛检", "健康"]
        },
        {
          route: "/SimplifiedChinese/lung-cancer-risk-calculator",
          label: "估算我的肺癌风险",
          title: "估算我的肺癌风险",
          page: "CalculatePage",
          hashTagList: ["shouldiscreen", "肺癌", "筛检", "健康"]
        },
        {
          route: "/SimplifiedChinese/result",
          label: "结果",
          title: "我的肺癌风险",
          page: "ResultPage",
          hashTagList: ["shouldiscreen", "肺癌", "筛检", "健康"]
        },
        {
          route: "/SimplifiedChinese/disclaimer",
          label: "免责声明",
          title: "免责声明",
          page: "DisclaimerPage",
          hashTagList: ["shouldiscreen", "肺癌", "筛检", "健康"]
        },
        {
          route: "/SimplifiedChinese/development-team",
          label: "研发团队",
          title: "研发团队",
          page: "TeamPage",
          hashTagList: ["shouldiscreen", "肺癌", "筛检", "健康"]
        },
        {
          route: "/SimplifiedChinese/development-history",
          label: "研发历程",
          title: "研发历程",
          page: "HistoryPage",
          hashTagList: ["shouldiscreen", "肺癌", "筛检", "健康"]
        },
        {
          route: "/SimplifiedChinese/feedback",
          label: "意见回馈",
          title: "意见回馈",
          page: "FeedbackPage",
          hashTagList: ["shouldiscreen", "肺癌", "筛检", "健康"]
        },
        {
          route: "/SimplifiedChinese/saved",
          label: "保存",
          title: "保存的内容",
          page: "SavedPage",
          hashTagList: ["shouldiscreen", "肺癌", "筛检", "健康"]
        },
        {
          route: "/SimplifiedChinese/publications",
          label: "研究出版品",
          title: "研究出版品",
          page: "PublicationPage",
          hashTagList: ["shouldiscreen", "肺癌", "筛检", "健康"]
        }
      ]
    },
    savedComponent: {
      saved_label: "Saved",
      saved_url: "/SimplifiedChinese/saved"
    }
  }
}
