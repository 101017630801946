import axios from "axios";
import MyUtility from "./MyUtility";

// testing locally
//const serverURL = "http://localhost:8888/service.php"; // "./service.php";


// testing draft deployment
//const serverURL = "https://peiyaoh.people.si.umich.edu/ShouldIScreen/service.php"; // "./service.php";


// testing on UM space 
//const serverURL = "https://lcs-decision-beta.com/service.php"; // "./service.php";


// with shoudliscreen.com
const serverURL = "https://shouldiscreen.com/service.php"; // "./service.php";


// with beta.shoudliscreen.com
//const serverURL = "https://beta.shouldiscreen.com/service.php"; // "./service.php";

// with equal.shoudliscreen.com
//const serverURL = "https://equal.shouldiscreen.com/service.php"; // "./service.php";


class ServerService {
  constructor() {
    console.log("ServerService: constructor");
  }

  static insertSession(
    deviceInfo,
    deviceType,
    innerWidth,
    innerHeight,
    feedback_id = "default_id",
    pack_id = "default_id",
    reflection_id = "default_id",
    risk_id = "default_id",
    language="default_lang"
  ) {
    console.log("insertSession: " + serverURL);

    // using URLSearchParams();
    /*
    let parameters = new URLSearchParams();
    parameters.set("function_name", "submit_pack_calculation");
    */
    // using plain object
    let parameters = {
      function_name: "insert_session"
    };


    var dataParameters = {};
    dataParameters["device_info"] = deviceInfo;
    dataParameters["device_type"] = deviceType;
    dataParameters["inner_width"] = innerWidth;
    dataParameters["inner_height"] = innerHeight;

    dataParameters["feedback_id"] = feedback_id;
    dataParameters["pack_id"] = pack_id;
    dataParameters["reflection_id"] = reflection_id;
    dataParameters["risk_id"] = risk_id;
    dataParameters["language"] = language;

    let options = {
      url: serverURL,
      method: "post",
      // application/x-www-form-urlencoded
      //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      headers: { "Content-Type": "application/json" },
      params: parameters,
      data: dataParameters
    };

    return axios(options);
  }

  static insertAnnotation(
    annotationObj,
    feedback_id = "default_id",
    pack_id = "default_id",
    reflection_id = "default_id",
    risk_id = "default_id",
    session_id = "default_id",
    language="default_lang"
  ) {
    console.log("insertAnnotation: " + serverURL);

    // using URLSearchParams();
    /*
    let parameters = new URLSearchParams();
    parameters.set("function_name", "submit_pack_calculation");
    */
    // using plain object
    let parameters = {
      function_name: "insert_annotation"
    };


    var dataParameters = {};
    dataParameters["session_id"] = session_id;

    dataParameters["title"] = annotationObj.title;
    dataParameters["pathname"] = annotationObj.pathname;
    dataParameters["category"] = annotationObj.category;
    dataParameters["content"] = annotationObj.content;

    dataParameters["feedback_id"] = feedback_id;
    dataParameters["pack_id"] = pack_id;
    dataParameters["reflection_id"] = reflection_id;
    dataParameters["risk_id"] = risk_id;
    dataParameters["language"] = language;

    let options = {
      url: serverURL,
      method: "post",
      // application/x-www-form-urlencoded
      //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      headers: { "Content-Type": "application/json" },
      params: parameters,
      data: dataParameters
    };

    return axios(options);
  }

  static submitPackCalculation(
    years,
    amount,
    result,
    session_id = "default_id",
    feedback_id = "default_id",
    reflection_id = "default_id",
    risk_id = "default_id",
    language="default_lang"
  ) {
    console.log("submitPackCalculation: " + serverURL);

    // using URLSearchParams();
    /*
    let parameters = new URLSearchParams();
    parameters.set("function_name", "submit_pack_calculation");
    */
    // using plain object
    let parameters = {
      function_name: "submit_pack_calculation"
    };


    var dataParameters = {};
    dataParameters["years"] = years;
    dataParameters["amount"] = amount;
    dataParameters["result"] = result;
    dataParameters["session_id"] = session_id;
    dataParameters["feedback_id"] = feedback_id;
    dataParameters["reflection_id"] = reflection_id;
    dataParameters["risk_id"] = risk_id;
    dataParameters["language"] = language;

    let options = {
      url: serverURL,
      method: "post",
      // application/x-www-form-urlencoded
      //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      headers: { "Content-Type": "application/json" },
      params: parameters,
      data: dataParameters
    };

    return axios(options);
  }

  static submitRiskConfirmation(
    layoutChoice,
    calculatedRisk,
    formRiskConfirmation,
    confirmationEvaluation,
    gender="",
    insurance="",
    eligibility="",
    session_id = "default_id",
    risk_id = "default_id",
    risk_confirmation_id = "default_id",
    feedback_id = "default_id",
    pack_id = "default_id",
    reflection_id = "default_id",
    language="default_lang"
  ) {
    console.log("submitRiskConfirmation: " + serverURL);

    // using URLSearchParams
    /*
    let parameters = new URLSearchParams();
    parameters.set("function_name", "submit_risk_calculation");
    */

    // using plain object
    let parameters = {
      function_name: "submit_risk_confirmation"
    };

    var dataParameters = {};

    dataParameters["layout_choice"] = layoutChoice;
    dataParameters["calculated_risk"] = calculatedRisk;
    dataParameters["risk_confirmation"] = formRiskConfirmation;
    dataParameters["confirmation_evaluation"] = confirmationEvaluation;

    dataParameters["gender"] = gender;
    dataParameters["insurance"] = insurance;
    dataParameters["eligibility"] = eligibility;
    
    dataParameters["session_id"] = session_id;
    dataParameters["risk_id"] = risk_id;
    dataParameters["risk_confirmation_id"] = risk_confirmation_id;
    dataParameters["feedback_id"] = feedback_id;
    dataParameters["pack_id"] = pack_id;
    dataParameters["reflection_id"] = reflection_id;
    dataParameters["language"] = language;

    let options = {
      url: serverURL,
      method: "post",
      // application/x-www-form-urlencoded
      //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      headers: { "Content-Type": "application/json" },
      params: parameters,
      data: dataParameters
    };

    return axios(options);
  }

  static submitBenefitConfirmation(
    chartIndex,
    chartDescription,
    chartType,
    chartBase,
    chartMode,
    calculatedRisk,
    formBenefitConfirmation,
    gender="",
    insurance="",
    eligibility="",
    deviceInfo={},
    deviceType="",
    innerWidth=0,
    innerHeight=0,
    session_id = "default_id",
    risk_id = "default_id",
    risk_confirmation_id = "default_id",
    benefit_confirmation_id = "default_id",
    feedback_id = "default_id",
    pack_id = "default_id",
    reflection_id = "default_id",
    language="default_lang"
  ) {
    console.log("submitBenefitConfirmation: " + serverURL);

    // using URLSearchParams
    /*
    let parameters = new URLSearchParams();
    parameters.set("function_name", "submit_risk_calculation");
    */

    // using plain object
    let parameters = {
      function_name: "submit_benefit_confirmation"
    };

    var dataParameters = {};

    dataParameters["chart_index"] = chartIndex;
    dataParameters["chart_description"] = chartDescription;
    dataParameters["chart_type"] = chartType;
    dataParameters["chart_base"] = chartBase;
    dataParameters["chart_mode"] = chartMode;





    dataParameters["calculated_risk"] = calculatedRisk;
    dataParameters["benefit_confirmation"] = formBenefitConfirmation;
    //dataParameters["confirmation_evaluation"] = confirmationEvaluation;

    dataParameters["gender"] = gender;
    dataParameters["insurance"] = insurance;
    dataParameters["eligibility"] = eligibility;

    dataParameters["device_info"] = deviceInfo;
    dataParameters["device_type"] = deviceType;
    dataParameters["inner_width"] = innerWidth;
    dataParameters["inner_height"] = innerHeight;
    
    dataParameters["session_id"] = session_id;
    dataParameters["risk_id"] = risk_id;
    dataParameters["risk_confirmation_id"] = risk_confirmation_id;
    dataParameters["benefit_confirmation_id"] = benefit_confirmation_id;
    dataParameters["feedback_id"] = feedback_id;
    dataParameters["pack_id"] = pack_id;
    dataParameters["reflection_id"] = reflection_id;
    dataParameters["language"] = language;

    let options = {
      url: serverURL,
      method: "post",
      // application/x-www-form-urlencoded
      //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      headers: { "Content-Type": "application/json" },
      params: parameters,
      data: dataParameters
    };

    return axios(options);
  }

  static submitRiskCalculation(
    formOld,
    formCurrentStatus,
    formAgeLastSmoke,
    formYearsSmoking,
    formCigarettesPerDay,
    formGender,
    formInsurance,
    formHighestGradeCompleted,
    formRaceEthnicity,
    formTallFeet,
    formTallInch,
    formWeight,
    formDoctorToldCancer,
    formFamilyHistory,
    formDoctorToldCOPD,
    calculatedRisk,
    eligibility,
    session_id = "default_id",
    feedback_id = "default_id",
    pack_id = "default_id",
    reflection_id = "default_id",
    language="default_lang"
  ) {
    console.log("submitRiskCalculation: " + serverURL);

    // using URLSearchParams
    /*
    let parameters = new URLSearchParams();
    parameters.set("function_name", "submit_risk_calculation");
    */

    // using plain object
    let parameters = {
      function_name: "submit_risk_calculation"
    };

    var dataParameters = {};

    dataParameters["old"] = formOld;
    dataParameters["current_status"] = formCurrentStatus;

    dataParameters["age_last_smoke"] = formAgeLastSmoke;
    dataParameters["years_smoking"] = formYearsSmoking;
    dataParameters["cigarettes_per_day"] = formCigarettesPerDay;
    dataParameters["gender"] = formGender;
    dataParameters["insurance"] = formInsurance;
    dataParameters["highest_grade_completed"] = formHighestGradeCompleted;
    dataParameters["race_ethnicity"] = formRaceEthnicity;
    dataParameters["tall_feet"] = formTallFeet;
    dataParameters["tall_inch"] = formTallInch;
    dataParameters["weight"] = formWeight;
    dataParameters["doctor_told_cancer"] = formDoctorToldCancer;
    dataParameters["family_history"] = formFamilyHistory;
    dataParameters["doctor_told_copd"] = formDoctorToldCOPD;
    // calculatedRisk
    dataParameters["calculated_risk"] = calculatedRisk;

    dataParameters["eligibility"] = eligibility;
    
    dataParameters["session_id"] = session_id;
    dataParameters["feedback_id"] = feedback_id;
    dataParameters["pack_id"] = pack_id;
    dataParameters["reflection_id"] = reflection_id;
    dataParameters["language"] = language;

    let options = {
      url: serverURL,
      method: "post",
      // application/x-www-form-urlencoded
      //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      headers: { "Content-Type": "application/json" },
      params: parameters,
      data: dataParameters
    };

    return axios(options);
  }

  static submitReflection(
    benefit,
    recommendation,
    risk,
    reason,
    session_id = "default_id",
    feedback_id = "default_id",
    pack_id = "default_id",
    risk_id = "default_id",
    risk_confirmation_id = "default_id",
    language="default_lang"
  ) {
    console.log("submitReflection: " + serverURL);

    // using URLSearchParams
    /*
    let parameters = new URLSearchParams();
    parameters.set("function_name", "submit_reflection");
    */

        // using plain object
        let parameters = {
          function_name: "submit_reflection"
        };

    var dataParameters = {};
    dataParameters["benefit"] = benefit;
    dataParameters["recommendation"] = recommendation;
    dataParameters["risk"] = risk;
    dataParameters["reason"] = reason;
    dataParameters["session_id"] = session_id;
    dataParameters["feedback_id"] = feedback_id;
    dataParameters["pack_id"] = pack_id;
    dataParameters["risk_id"] = risk_id;
    dataParameters["risk_confirmation_id"] = risk_confirmation_id;
    dataParameters["language"] = language;

    let options = {
      url: serverURL,
      method: "post",
      headers: { "Content-Type": "application/json" },
      params: parameters,
      data: dataParameters
    };

    return axios(options);
  }

  static submitFeedback(
      gender,
      smoke_status,
      age,
      calculator_result,
      information_receiver,
      find_all_information,
      help_conversation_doctor,
      help_decision,
      interest_reason,
      comment,
      cached_session_id = "default_id",
      cached_pack_id = "default_id" ,
      cached_risk_id = "default_id",
      cached_reflection_id = "default_id",
      language="default_lang"
  ) {
    console.log("submitFeedback " + serverURL);

    // using URLSearchParams
    /*
    let parameters = new URLSearchParams();
    parameters.set("function_name", "submit_feedback");
    */

    // using plain object
    let parameters = {
      function_name: "submit_feedback"
    };

    var dataParameters = {};
    dataParameters["gender"] = gender;
    dataParameters["smoke_status"] = smoke_status;
    dataParameters["age"] = age;
    dataParameters["calculator_result"] = calculator_result;
    dataParameters["information_receiver"] = information_receiver;
    dataParameters["find_all_information"] = find_all_information;

    dataParameters["help_conversation_doctor"] = help_conversation_doctor;
    dataParameters["help_decision"] = help_decision;
    dataParameters["interest_reason"] = interest_reason;
    dataParameters["comment"] = comment;
    dataParameters["session_id"] = cached_session_id;
    dataParameters["pack_id"] = cached_pack_id;
    dataParameters["risk_id"] = cached_risk_id;
    dataParameters["reflection_id"] = cached_reflection_id;
    dataParameters["language"] = language;

    console.log("dataParameters: " + JSON.stringify(dataParameters));

    let options = {
      url: serverURL,
      method: "post",
      headers: { "Content-Type": "application/json" },
      params: parameters,
      data: dataParameters
    };

    return axios(options);
  }

}

export default ServerService;
