import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import MyPager from "../components/MyPager";
import MyCalculation from "../utils/MyCalculation";


export default class English {
  constructor() {
    console.log("English: constructor");
  }
  static content =  {
    site: {
      loading_message: "Loading...",
      ie_message: <div className="lead">
      Looks like you are using Internet Explorer (IE). Our site works best with Chrome, and we might not be able to support IE in the future. Please consider using Chrome, Edge, or Firefox for the best results.<br /><br />
      You can download Chrome here: <a
                  target="_blank"
                  href="https://www.google.com/chrome/"
                  rel="noopener noreferrer"
                >
                  Chrome
                </a>
      </div>,
      share_button_label: {
        share: "Share this page",
        hide: "Hide sharing options"
      }
    },
    benefitHarmPage: {
      banner_image_source: "/image/FLIKR+Yale+Rosen+CC2.0.jpg",
      banner_title: "Lung Cancer Screening",
      title: "Things you should know about lung cancer screening",
      benefit_harm_content: {
        benefit: {
          title: "WHAT ARE THE BENEFITS OF SCREENING?",
          content:
            "For people who are eligible for screening and decide to get screened, the chances of finding cancer early is higher. Finding cancer early generally means that more successful treatments can be offered. A recent study showed that after 6.5 years, among those who were eligible for screening, those who were screened with CT were 20% less likely to die from lung cancer compared to those who were not screened with CT."
        },
        harm: {
          title: "WHAT ARE THE HARMS OF SCREENING?",
          content: (
            <div>
              <p>
                Lung cancer screening only helps to find cancer if it is
                already there. It cannot prevent cancer. The best way to
                prevent cancer is to stop smoking if you have not already done
                so. The CT scan for lung cancer screening uses a lower dose of radiation 
                compared to a conventional chest CT scan: up to 90% less. This radiation dose                   
               is equal to about 6 months of radiation from your every day 
                environment. The additional risk of developing fatal lung cancer 
                from one adult exam is considered to be extremely low. For those of you
                interested in the total dose of radiation, you can visit&nbsp;
                <a
                  target="_blank"
                  href="http://www.xrayrisk.com/calculator/calculator-normal-studies.php?id=68"
                  rel="noopener noreferrer"
                >
                  this site
                </a>
                . Other potential harms from screening include: false alarms,
                overtreatment, more testing, and invasive procedures.
              </p>
            </div>
          )
        }
      },
      sample_list: [
        {
          title: "FALSE ALARMS",
          content: (
            <div>
              <p>
                Screening works by finding lung nodules, some of which are cancer,
                but most nodules detected on the low-dose CT scan for lung cancer
                screening are <strong>not</strong> cancer. A lung nodule is a round 
                or oval shaped spot. With the current nodule 
                management guidelines, the false positive rate for lung cancer 
                is low at about 10-12% (
                <a
                  target="_blank"
                  href="https://pubmed.ncbi.nlm.nih.gov/29632062/"
                  rel="noopener noreferrer"
                >
                  McKee et al.
                </a>
                ). 
                
                False positive findings can cause anxiety. However, understanding that 
                most lung nodules found from the CT screening do <strong>not </strong> 
                represent cancer helps most avoid significant anxiety while they wait 
                for follow-up testing.
              </p>
            </div>
          )
        },
        {
          title: "OVERTREATMENT (OR OVERDIAGNOSIS)",
          content: (
            <div>
              <p>
                There are times when screening will find a nodule that is
                cancer but was never going to cause problems. This can lead to
                treatments such as surgery, chemotherapy, and radiation which
                are unnecessary and considered overtreatment. Overtreatment
                can cause side effects that make you feel sick.
              </p>
            </div>
          )
        },
        {
          title: "MORE TESTING",
          content: (
            <div>
              <p>
                In order to prove a nodule is not cancer, the proper
                recommendation is generally to take additional follow up scans
                to monitor for changes. Your doctor will make recommendations
                for the proper follow up interval to monitor any lung nodules
                detected. Most often, this is at 6-month to 12-month
                intervals.
              </p>
            </div>
          )
        },
        {
          title: "INVASIVE PROCEDURES",
          content: (
            <div>
              <p>
                Sometimes further testing for a finding on lung cancer
                screening leads to the need for additional testing that can
                include invasive procedures (biopsies or surgery). These tests
                are risky and can cause harm in people that end up not having
                had cancer. Invasive procedures can result in minor
                complications such as bleeding and infections. They can also
                result in more major complications such as a collapsed lung
                and even death.
              </p>
            </div>
          )
        }
      ],
      more_info: (
        <p>
          For more details, you can visit the{" "}
          <a
            href={"https://www.cancer.gov/types/lung/research/nlst-qa"}
            target="_blank"
            rel="noopener noreferrer"
          >
            {"Q & A"}
          </a>{" "}
          compiled by the National Cancer Institute that explains the findings from the national lung screening trial, or the <a
            href={"https://screenyourlungs.org/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            {"screenyourlungs.org"}
          </a>  website.
        </p>
      ),
      image_info: {
        head: "Image: ",
        src: "https://www.flickr.com/photos/pulmonary_pathology/",
        text: "Yale Rosen/Flickr. CC BY-SA 2.0."
      }
    },
    homePage: {
      banner_title: "Lung Cancer Screening",
      banner_content: "Should I get screened?",
      banner_button_label: "Click to learn more",
      banner_button_link: "/English/how-is-screening-done",
      two_column_title: "We can help you.",
      two_column_sub_title:
        "Deciding whether or not to go through lung cancer CT screening is not easy. Here, there is up to date information provided by doctors to help you make an informed choice.",
      //"Deciding whether or not to go through lung cancer CT screening is not easy. We have put together a road map of what you should know based on where you are in this important decision.",
      two_column_content: {
        first: {
          title: "Who is eligible for lung cancer screening?",
          sub_title: (
            <div>
              <p>
                The US Preventive Services Task Force recently updated the eligibility guidelines for lung cancer screening to the following:
              </p>
              <ul>
                <li>50-80 year olds</li>
                <li>Smoked within past 15 years</li>
                <li>
                  20 pack-years (see{" "}
                  <Link
                    to={"/English/pack-year-calculator"}
                    
                    // not working
                    //to={this.getRouteByPageAndLanguage("CalculatorPage", "English")}
                    style={{ width: "100%", textDecoration: "none" }}
                    data-on="click"
                    data-event-category="HomePage"
                    data-event-action={"/English/pack-year-calculator"}
                  >
                    pack calculator
                  </Link>
                  )
                </li>
              </ul>
              <p>
                Medicare and Medicaid coverage guidelines for lung cancer screening (as of 10 February 2022) are:
              </p>
              <ul>
                <li>50-77 year olds</li>
                <li>Smoked within past 15 years</li>
                <li>
                  20 pack-years (see{" "}
                  <Link
                    to={"/English/pack-year-calculator"}
                    
                    // not working
                    //to={this.getRouteByPageAndLanguage("CalculatorPage", "English")}
                    style={{ width: "100%", textDecoration: "none" }}
                    data-on="click"
                    data-event-category="HomePage"
                    data-event-action={"/English/pack-year-calculator"}
                  >
                    pack calculator
                  </Link>
                  )
                </li>
              </ul>
            </div>
          ),
          image: "/image/questioningDude.jpg",
          button_label: "Learn More",
          link: "/English/how-is-screening-done"
        },
        second: {
          title: "Lung Cancer Risk Calculator",
          sub_title:
            "Do you want to know if you should be screened? Use our calculator to see your personalized lung cancer risk.",
          image: "/image/SIScalculator.jpg",
          button_label: "Use it",
          link: "/English/lung-cancer-risk-calculator"
        }
      },
      time_navigation: [
        {
          title: "1. Why?",
          items: [
            {
              title: "Why should I be thinking about this?",
              content: (
                <div>
                  <p>
                    Screening can help find lung cancer at an early stage,
                    when more successful treatment can be offered. Without screening,
                    lung cancer is usually found at a late stage.{" "}
                  </p>
                  <div className="float-right">
                    <MyPager
                      link={"/English/how-is-screening-done"}
                      label={"Learn more"}
                      arrowDirection={"right"}
                    ></MyPager>
                  </div>
                </div>
              )
            },
            {
              title: "What are the warning signs of lung cancer?",
              content: (
                <p>
                  Symptoms like pain, unexpected or unplanned weight loss, 
                  coughing up blood, or a changing cough that won't go away 
                  are concerning for lung cancer, especially if you are a smoker.{" "}
                  <strong>
                    But lung cancer screening is not for people with symptoms
                  </strong>
                  . If you have any symptoms that concern you, please discuss
                  them with your physician.
                </p>
              )
            },
            {
              title: "What is lung cancer CT screening?",
              content: (
                <div>
                  <p>
                    Lung cancer screening involves getting a CT (or CAT) scan
                    of your chest. The procedure involves you lying still and
                    going through a doughnut-shaped machine while multiple
                    images are taken using X-rays and a computer.{" "}
                  </p>
                  <div className="float-right">
                    <MyPager
                      link={"/English/how-is-screening-done"}
                      label={"Learn more"}
                      arrowDirection={"right"}
                    ></MyPager>
                  </div>
                </div>
              )
            }
          ]
        },
        {
          title: "2. Cost",
          items: [
            {
              title: "Cost",
              content: (
                <div>
                  <p>
                    If you are uninsured, the initial lung cancer screening
                    can cost you $100-$400 out-of-pocket. Visit the insurance
                    page by clicking the button below to find out if your
                    health insurance covers lung cancer screening for you.
                  </p>
                  <p>
                    <div className="float-right">
                      <MyPager
                        link={"/English/coverage"}
                        label={"Learn more"}
                        arrowDirection={"right"}
                      ></MyPager>
                    </div>
                  </p>
                </div>
              )
            },
            {
              title: "Where can I get screened?",

              content: (
                <div>
                  <p>
                    It is recommended that you get lung cancer screening at a
                    facility that is accredited by the American College of
                    Radiology. Click&nbsp;
                    <a
                      target="_blank"
                      href="
                      https://www.acr.org/Clinical-Resources/Lung-Cancer-Screening-Resources/LCS-Locator-Tool"
                      rel="noopener noreferrer"
                    >
                      here
                    </a>
                    &nbsp;to see a list of accredited facilities.
                  </p>
                </div>
              )
            }
          ]
        },
        {
          title: "3. Other things to consider",
          items: [
            {
              title: "Lung cancer screening is not a one-time thing.",
              content: (
                <div>
                  <p>
                    Lung cancer screening is a process. It is not a one-time
                    thing. To get the full benefit of screening, you need to
                    be screened once a year until you are no longer eligible.
                    Also, screening may lead to finding a nodule that needs a
                    follow-up CT scan within 3-6 months.{" "}
                  </p>
                </div>
              )
            },

            {
              title: "About potential harms",
              content: (
                <div>
                  <p>
                    You may want to consider the possibility of false
                    positives and overtreatment from lung cancer screening.
                  </p>
                  <p>
                    <div className="float-right">
                      <MyPager
                        link={"/English/benefits-and-harms-screening"}
                        label={"Learn more"}
                        arrowDirection={"right"}
                      ></MyPager>
                    </div>
                  </p>
                </div>
              )
            },
            {
              title:
                "Other risk factors for lung cancer, other than cigarette smoking",
              content: (
                <div>
                  <p>
                    While cigarette smoking is by far the largest contributor
                    to lung cancer risk, radon and occupational exposures
                    (e.g. asbestos, arsenic, silica, cadmium) are also risk
                    factors for lung cancer.
                  </p>
                  <p>
                    <div className="float-right">
                      <MyPager
                        link={"/English/causes-of-lung-cancer"}
                        label={"Learn more"}
                        arrowDirection={"right"}
                      ></MyPager>
                    </div>
                  </p>
                </div>
              )
            }
          ]
        }
      ]
    },
    causePage: {
      banner_image_source: "/image/smoke.jpg",
      banner_title: "Lung Cancer Screening",
      cause_info: {
        title: "Lung cancer and its causes",
        causes: [
          <p>
            Lung cancer starts with cells in the lung airway that have suffered
            permanent damage. This damage results in an abnormal uncontrolled
            growth of cells and can spread to other parts of your body
            (metastasis). Lung cancer is the leading cause of cancer related
            death in the world.
          </p>,
          <p>
            There are many causes linked to lung cancer. The most important
            and well understood risk factor is{" "}
            <strong>cigarette smoking</strong>.
          </p>
        ]
      },
      cause_chart: {
        title: "Estimated proportions of lung cancer causes*",
        src: "/image/attributable_risks_tiff2_English.png",
        text:
          "*Alberg AJ, Samet JM. Epidemiology of Lung Cancer. Chest. 2003; 123:21-49."
      },
      risk_info: {
        title: "Other known risk factors for lung cancer are:",
        risks: [
          "long term exposure to radon (a colorless, odorless gas that miners are exposed to, and which can accumulate in the basement of some homes);",
          "occupational exposure to asbestos, arsenic, and some types of chromium and silica;",
          "a diagnosis of Chronic Obstructive Pulmonary Disease (COPD), or emphysema and/or chronic bronchitis, made by a doctor; and",
          "a family history of lung cancer."
        ]
      }
    },
    comparePage: {
      image_info: {
        link: "/image/fruit.png",
        attribution: {
          link: "https://creativecommons.org/licenses/by-sa/2.0/",
          label: "MicroAssist/Flickr: CC BY-SA 2.0"
        }
      },
      title:
        "How does lung cancer CT screening compare with other kinds of screening?",
      sub_title: "DID YOU KNOW?",
      content:
        "Compared to other common cancer screening tests such as mammography for breast cancer, lung cancer CT screening is at least as effective at reducing lung cancer deaths in high risk individuals as mammography is at preventing breast cancer deaths.",
      comparison_table: {
        column_title_list: [
          <div>
            <center>
              Type of cancer screening<sup>1</sup>
            </center>
          </div>,
          <div>
            <center>Years of annual screens</center>
          </div>,
          <div>
            <center>
              Number of cancer deaths prevented<sup>2</sup>
            </center>
          </div>
        ],
        row_list: [
          {
            title: "Lung",
            cell_list: [3, 3.1]
          },
          {
            title: "Colorectal",
            cell_list: [5, 2.8]
          },
          {
            title: "Breast",
            cell_list: ["", ""]
          },
          {
            title: "Age 60-69",
            cell_list: [10, 2.6]
          },
          {
            title: "Age 50-59",
            cell_list: [10, 0.8]
          }
        ]
      },
      foot_note_list: [
        <div>
          <sup>1</sup>The procedures corresponding to lung, colorectal, and
          breast cancer screening here are: low-dose CT scan, sigmoidoscopy
          with fecal occult blood testing, and mammography respectively.
        </div>,
        <div>
          <sup>2</sup>This indicates the number of deaths prevented per 1000
          people screened.
        </div>
      ]
    },
    disclaimerPage: {
      title: "Disclaimer",
      content:
        "The information provided in this decision aid is for general health information only. The risk calculator can only give estimates of your risk based on the responses you provide. Even though we try to provide the best estimates available, the numbers may not be accurate for an individual person. There may be specific risk factors that are not measured.  Therefore, the information provided on this website is not to be used as a substitute for medical advice from a health professional. If you are concerned about lung cancer or your risk of getting lung cancer, please talk to your doctor or other health care provider. The Site contains links to other sites operated by third parties, and we do not guarantee the representation about any Third Party Site or the accuracy of its content."
    },
    howPage: {
      title: "Things you should know about lung cancer screening",
      banner_image_source: "/image/um-ct-shoot.jpg",
      banner_title: "Lung Cancer Screening",
      sample_list: [
        {
          title: "Q1. What is lung cancer CT screening?",
          content: (
            <div>
              <p>
                Lung cancer screening uses low-dose computed tomography (LDCT)
                i.e. a CT scan with a low dose of radiation, to find lung
                nodules, some of which may be cancer. People who take part in
                screening can lower their chances of dying from lung cancer.
              </p>
              <p>
                In general, lung cancer screening is not a single test but a
                process under the direction of your doctor(s) that includes
                follow-up of any findings and return for screening every year
                while you remain eligible.
              </p>
            </div>
          )
        },
        {
          title: "Q2. Who is eligible for screening?",
          content: (
            <div>
              <p>
                Lung cancer screening is not appropriate for everybody. You
                qualify for screening if you:
              </p>
              <ul>
                <li>are between 50 and 80 years old,</li>
                <li>have smoked within the last 15 years, and</li>
                <li>have smoked 20 pack-years or more,</li>
              </ul>
              <p>
                according to the United States Preventive Services Task Force.
                A pack-year is used to describe how many cigarettes you have
                smoked in your lifetime, with a pack equal to 20 cigarettes.
                If you have smoked a pack a day for the last 20 years, or two
                packs a day for the last 10 years, you have 20 pack-years.
                Click{" "}
                <Link
                  to={"/English/pack-year-calculator"}
                  style={{ width: "100%", textDecoration: "none" }}
                  data-on="click"
                  data-event-category="HowPage"
                  data-event-action={"/English/pack-year-calculator"}
                >
                  here
                </Link>{" "}
                to calculate how many pack years you have.
              </p>
              <p>
                Note: High-risk individuals insured by Medicare have screening from the age 50 to 77.
              </p>
            </div>
          )
        },
        {
          title: "Q3. What happens at the screening?",
          content: (
            <div>
              <p>
                The following description is adapted from the{" "}
                <a
                  href="https://www.radiologyinfo.org/en/info.cfm?pg=screening-lung#part_three"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  American College of Radiology
                </a>
                :
              </p>
              <p>
                For a low-dose CT screening, you will lie flat on the exam
                table. Pillows and straps may be used to help you
                maintain the correct position and remain still
                during the exam. You will usually be asked to hold your arms
                over your head. Next, the table will move through the scanner
                to the correct starting position for the scans. Then, while
                you hold your breath for 5 to 10 seconds, the table will move
                through the machine as the actual CT scan is done.
              </p>
            </div>
          )
        },
        {
          title: "Q4. What happens after my first screening?",
          content: (
            <div>
              <p>
                If a lung nodule is found on your CT scan, your doctor may
                recommend a follow-up CT scan, usually 3 - 6 months later to check
                that the nodule has not grown. A lung nodule is a round or oval
                shaped spot. In the unlikely case that the
                nodule does grow or may present a worry, your doctor may
                recommend further testing using a{" "}
                <a
                  href="https://medlineplus.gov/ency/article/007342.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PET scan
                </a>{" "}
                or a biopsy (taking out a small piece of the nodule).
              </p>
              <p>
                However, since most lung nodules found are not
                cancer, you may only need to go back in one year as with other
                annual health checks to continue the process of screening.
                Remember, lung cancer screening is not a single test: it is a
                process that must be done correctly under the direction of
                your doctor(s).
              </p>
            </div>
          )
        },
        {
          title:
            "Q5. How much radiation will I be exposed to from the CT scan for lung cancer screening?",
          content: (
            <div>
              <p>
                A CT scan for lung cancer screening uses up to 90% less
                radiation than a conventional chest CT scan. The approximate
                effective radiation dose is 1.5 millisievert (mSv), which is
                equal to about 6 months of "normal" background radiation and
                has very low risk. In fact, no correlation has been found
                between background radiation and cancer risk. The additional
                risk of developing fatal lung cancer from one adult exam is
                low enough that it is difficult to measure, with the estimated
                chances being between 1 in 100,000 and 1 in 10,000. For more
                information on exposure to radiation, you may want to visit{" "}
                <a
                  href="https://www.radiologyinfo.org/en/info.cfm?pg=safety-xray"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>{" "}
                or{" "}
                <a
                  href="http://www.xrayrisk.com/calculator/calculator-normal-studies.php?id=68"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                .
              </p>
            </div>
          )
        },
        {
          title: "Q6. Where can I get screened?",
          content: (
            <div>
              <p>
                You should only be screened at an accredited facility. You can
                find one close to you by going{" "}
                <a
                  href="https://www.acr.org/Clinical-Resources/Lung-Cancer-Screening-Resources/LCS-Locator-Tool"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                .
              </p>
            </div>
          )
        }
      ]
    },
    riskPage: {
      banner_image_source: "/image/gsh-sa-balloons.jpg",
      banner_title: "Reducing Your Risk of Lung Cancer",
      title:
        "The best way to lower your chances of getting lung cancer is to quit smoking.",

      description: (
        <p>
          While LDCT screening can reduce your chances of dying from lung
          cancer, a <strong>far more</strong> effective means of reducing your
          chances of dying from lung cancer is to stop smoking if you
          currently smoke. This is difficult, but it can be done. To stop
          smoking, you need to overcome both the nicotine cravings, as well as
          a deeply ingrained habit. Many people cite "stress" as a reason they
          do not choose to try and quit. Stress is a part of every day life
          for most people, and waiting for stress to "go away" before doing
          something beneficial for your health is a recipe for failure. We
          must learn different ways to cope with stress, and talking with your
          doctor about your sources of stress is a good start. There are many
          resources out there that can help you quit, and some will provide
          you FDA approved smoking cessation aids for free, or at a greatly
          reduced prices. Here are just a few:
        </p>
      ),
      state_resource_list: [
        { state: "Select a state...", links: [] },
        ,
        {
          state: "Alabama",
          links: [
            {
              label:
                "Alabama Department of Public Health, Tobacco Prevention and Control",
              link: "http://www.adph.org/tobacco/Default.asp?id=779"
            }
          ]
        },

        {
          state: "Alaska",
          links: [
            {
              label: "Tobacco-Free Alaska",
              link:
                "http://dhss.alaska.gov/dph/Chronic/Pages/Tobacco/TobaccoFreeAlaska/default.aspx"
            }
          ]
        },

        {
          state: "Arizona",
          links: [
            {
              label: "ASHLine - Arizona's Smokers' Helpline",
              link: "https://ashline.org/"
            },
            {
              label: "Tobacco Free Arizona",
              link: "http://azdhs.gov/tobaccofreeaz/"
            }

            
          ]
        },

        {
          state: "Arkansas",
          links: [
            {
              label: "Arkansas Tobacco Cessation Resources",
              link:
                "http://www.healthy.arkansas.gov/programs-services/topics/tobacco-cessation-resources"
            },
            {
              label: "Stamp Out Smoking",
              link: "http://www.stampoutsmoking.com/get-help-to-quit/"
            }
          ]
        },

        {
          state: "California",
          links: [
            {
              label: "California Tobacco Control Program",
              link:
                "https://www.cdph.ca.gov/Programs/CCDPHP/DCDIC/CTCB/Pages/CessationServicesAndResources.aspx"
            },
            {
              label: "California Smokers' Helpline",
              link: "https://www.nobutts.org/"
            }
          ]
        },

        {
          state: "Colorado",
          links: [
            {
              label: "Colorado Department of Public Health and Environment",
              link: "https://www.colorado.gov/pacific/cdphe/quit-tobacco"
            },
            {
              label: "Tobacco Free CO",
              link: "http://www.tobaccofreeco.org/"
            }
          ]
        },

        {
          state: "Connecticut",
          links: [
            {
              label: "Connecticut Department of Public Health",
              link:
                "http://www.ct.gov/dph/cwp/view.asp?a=3137&q=388064&dphNav=|&dphNav_GID=1841"
            }
          ]
        },

        {
          state: "Delaware",
          links: [
            {
              label: "Delaware Tobacco Prevention and Control Program",
              link: "http://dhss.delaware.gov/dhss/dph/dpc/tobacco.html"
            }
          ]
        },

        {
          state: "Florida",
          links: [
            {
              label: "Tobacco Free Florida",
              link:
                "http://www.floridahealth.gov/programs-and-services/prevention/tobacco-free-florida/index.html"
            }
          ]
        },

        {
          state: "Georgia",
          links: [
            {
              label: "Georgia Department of Public Health - Ready to Quit",
              link: "https://dph.georgia.gov/ready-quit"
            }
          ]
        },

        {
          state: "Hawaii",
          links: [
            {
              label: "Hawaii Department of Health, Tobacco Control",
              link: "http://health.hawaii.gov/tobacco/home/cessation/"
            },
            {
              label: "Hawaii Tobacco Quitline",
              link: "http://hawaiiquitline.org"
            }
          ]
        },

        {
          state: "Idaho",
          links: [
            {
              label: "Idaho Tobacco Prevention and Control Program",
              link:
                "http://www.healthandwelfare.idaho.gov/Health/TobaccoPreventionandControl/tabid/324/Default.aspx"
            },
            { label: "Project Filter", link: "http://projectfilter.org/" }
          ]
        },

        {
          state: "Illinois",
          links: [
            {
              label: "Illinois Tobacco Quitline",
              link: "http://quityes.org/home.html"
            }
          ]
        },

        {
          state: "Indiana",
          links: [
            {
              label: "Indiana State Department of Health",
              link: "http://www.in.gov/isdh/tpc/2353.htm"
            },
            {
              label: "Quit Now Indiana",
              link: "http://www.quitnowindiana.com/"
            }
          ]
        },

        {
          state: "Iowa",
          links: [
            {
              label: "Quitline Iowa",
              link: "https://iowa.quitlogix.org/"
            }
          ]
        },

        {
          state: "Kansas",
          links: [
            {
              label: "Kansas Department of Health and Environment",
              link: "http://www.kdheks.gov/tobacco/cessation.html"
            },
            { label: "KanQuit!", link: "http://www.ksquit.org/" }
          ]
        },

        {
          state: "Kentucky",
          links: [
            {
              label: "Kentucky Tobacco Prevention and Cessation Program",
              link: "http://chfs.ky.gov/dph/mch/hp/tobacco.htm"
            }
          ]
        },

        {
          state: "Louisiana",
          links: [
            {
              label: "Quit with us, Louisiana",
              link: "http://www.quitwithusla.org/"
            }
          ]
        },

        {
          state: "Maine",
          links: [
            {
              label: "Partnership for a Tobacco-Free Maine",
              link: "http://www.tobaccofreemaine.org/"
            }
          ]
        },

        {
          state: "Maryland",
          links: [
          { 
            label: "Smoking stops here - Maryland's quit resource", 
            link: "https://smokingstopshere.com/" 
          },
          { 
            label: "MDQuit", 
            link: "http://mdquit.org/" 
          }
            ]
        },

        {
          state: "Massachusetts",
          links: [
            {
              label: "Make smoking history",
              link: "http://makesmokinghistory.org/"
            }
          ]
        },

        {
          state: "Michigan",
          links: [
            {
              label: "Michigan Department of Community Health",
              link:
                "http://www.michigan.gov/mdhhs/0,5885,7-339-71550_2955_2973_53244---,00.html"
            },
            {
              label: "University of Michigan Tobacco Treatment Programs",
              link:
                "https://hr.umich.edu/benefits-wellness/health-well-being/mhealthy/faculty-staff-well-being/alcohol-tobacco-programs/tobacco-consultation-service"
            }
          ]
        },

        {
          state: "Minnesota",
          links: [
            {
              label: "Minnesota Department of Health",
              link: "http://www.health.state.mn.us/divs/hpcd/tpc/quit.html"
            },
            {
              label: "QUITPLAN services. No judgments. Just help.",
              link: "https://www.quitplan.com/"
            }
          ]
        },

        {
          state: "Mississippi",
          links: [
            {
              label: "Mississippi Office of Tobacco Control",
              link:
                "http://www.msdh.state.ms.us/msdhsite/_static/43,0,94.html#community"
            },
            {
              label: "Mississippi Tobacco Quitline",
              link: "http://www.quitlinems.com/"
            }
          ]
        },

        {
          state: "Missouri",
          links: [
            {
              label: ">Missouri Department of Health and Senior Services",
              link:
                "http://health.mo.gov/living/wellness/tobacco/smokingandtobacco/tobaccocontrol.php"
            }
          ]
        },
        {
          state: "Montana",
          links: [
            {
              label: "Montana Tobacco Use Prevention Program",
              link: "http://dphhs.mt.gov/publichealth/mtupp"
            },
            {
              label: "Montana Tobacco Quitline",
              link: "https://montana.quitlogix.org/"
            }
          ]
        },
        {
          state: "Nebraska",
          links: [
            {
              label: "Smoke Free Nebraska",
              link:
                "http://dhhs.ne.gov/publichealth/Pages/smokefree_sf_ql.aspx"
            }
          ]
        },

        {
          state: "Nevada",
          links: [
            {
              label: "Nevada Tobacco and Control Program",
              link:
                "http://dpbh.nv.gov/Programs/TPC/Tobacco_Prevention_and_Control_-_Home/"
            },
            {
              label: "Nevada Tobacco Prevention Coalition",
              link:
                "http://www.tobaccofreenv.org/tobacco-information/quitting-smoking"
            }
          ]
        },

        {
          state: "New Hampshire",
          links: [
            {
              label: "New Hampshire Tobacco Prevention and Control Program",
              link: "http://www.dhhs.state.nh.us/dphs/tobacco/index.htm"
            },
            {
              label: "New Hampshire Tobacco Helpline",
              link: "http://trytostopnh.org/"
            }
          ]
        },
        {
          state: "New Jersey",
          links: [
            {
              label: "New Jersey Tobacco Control Program",
              link: "http://www.nj.gov/health/fhs/tobacco"
            },
            {
              label: "New Jersey Quitline",
              link: "http://www.njquitline.org/treatment.html"
            }
          ]
        },

        {
          state: "New Mexico",
          links: [
            {
              label: "TUPAC: Tobacco Use Prevention and Control",
              link: "http://nmtupac.com/#home"
            }
          ]
        },

        {
          state: "New York",
          links: [
            {
              label: "New York State Smokers' Quitline",
              link: "https://nysmokefree.com/"
            }
          ]
        },

        {
          state: "North Carolina",
          links: [
            {
              label: "QuitlineNC",
              link: "http://www.quitlinenc.com/"
            }
          ]
        },

        {
          state: "North Dakota",
          links: [
            {
              label: "North Dakota Tobacco Prevention and Control Program",
              link: "http://www.ndhealth.gov/tobacco/"
            },
            {
              label: "North Dakota Cessation/Quitting Programs",
              link: "http://www.ndhealth.gov/tobacco/cessation.htm"
            }
          ]
        },

        {
          state: "Ohio",
          links: [
            {
              label: "Ohio Department of Health Tobacco Program",
              link:
                "http://www.odh.ohio.gov/en/odhprograms/eh/quitnow/Tobacco/Cessation/Cessation"
            }
          ]
        },

        {
          state: "Oklahoma",
          links: [
            {
              label: "Oklahoma Tobacco Use Prevention and Reduction Programs",
              link:
                "http://www.ok.gov/health/Wellness/Tobacco_Prevention/Programs/index.html"
            }
          ]
        },

        {
          state: "Oregon",
          links: [
            {
              label: "Oregon Tobacco Prevention and Education Program",
              link:
                "http://www.oregon.gov/oha/PH/PreventionWellness/TobaccoPrevention/GetHelpQuitting/Pages/oregonquitline.aspx"
            },
            {
              label: "Smokefree Oregon",
              link: "https://www.quitnow.net/oregon/"
            }
          ]
        },

        {
          state: "Pennsylvania",
          links: [
            {
              label: "Pennsylvania Tobacco Prevention and Control",
              link:
                "http://www.health.pa.gov/My%20Health/Healthy%20Living/Smoke%20Free%20Tobacco/Pages/default.aspx#.WbqmgfmGPcs"
            },
            {
              label: "PA Free Quitline",
              link:
                "http://www.health.pa.gov/My%20Health/Healthy%20Living/Smoke%20Free%20Tobacco/Pages/PA-Free-Quitline.aspx#.WbrmPdOGORt"
            }
          ]
        },

        {
          state: "Rhode Island",
          links: [
            {
              label: "Rhode Island Tobacco Control Program",
              link: "http://www.health.ri.gov/programs/tobaccocontrol/"
            },
            { label: "Quit Now RI", link: "http://www.quitnowri.com/" }
          ]
        },
        {
          state: "South Carolina",
          links: [
            {
              label: "South Carolina Tobacco Cessation",
              link: "http://www.scdhec.gov/Health/TobaccoCessation/"
            }
          ]
        },
        {
          state: "South Dakota",
          links: [
            {
              label: "South Dakota Tobacco Prevention and Control Program",
              link: "http://doh.sd.gov/prevention/tobacco/"
            },
            {
              label: "South Dakota QuitLine",
              link: "https://www.sdquitline.com/"
            }
          ]
        },
        {
          state: "Tennessee",
          links: [
            {
              label: "Tennessee Tobacco Quitline",
              link: "http://health.state.tn.us/tobaccoquitline.htm"
            }
          ]
        },
        {
          state: "Texas",
          links: [
            {
              label: "Texas Department of State Health Services",
              link: "https://www.dshs.state.tx.us/tobacco/links.shtm"
            },
            { label: "Tobacco is foul", link: "http://www.ducktexas.org/" }
          ]
        },
        {
          state: "Utah",
          links: [
            {
              label: "Utah Tobacco Prevention and Control Program",
              link: "http://www.tobaccofreeutah.org/"
            },
            {
              label: "Way to Quit",
              link: "http://waytoquit.org/"
            }
          ]
        },

        {
          state: "Vermont",
          links: [
            {
              label: "Vermont Department of Health",
              link:
                "http://www.healthvermont.gov/prevent/tobacco/quit_smoking.aspx"
            },
            {
              label: "802 Quits: The Vermont Quit Smoking Resource",
              link: "http://802quits.org/"
            }
          ]
        },
        {
          state: "Virginia",
          links: [
            {
              label: "Virginia Tobacco Use Control Project",
              link: "http://www.vdh.virginia.gov/ofhs/prevention/tucp/"
            }
          ]
        },

        {
          state: "Washington",
          links: [
            {
              label:
                "Washington State Department of Health Tobacco Resources",
              link:
                "http://www.doh.wa.gov/ForPublicHealthandHealthcareProviders/PublicHealthSystemResourcesandServices/LocalHealthResourcesandTools/Tobacco"
            }
          ]
        },

        {
          state: "West Virginia",
          links: [
            {
              label: "West Virginia Tobacco Prevention Resources",
              link:
                "http://www.dhhr.wv.gov/wvdtp/cessation/Pages/default.aspx"
            }
          ]
        },

        {
          state: "Wisconsin",
          links: [
            {
              label: "Wisconsin Tobacco Prevention and Control Program",
              link: "https://www.dhs.wisconsin.gov/tobacco/index.htm"
            },
            {
              label: "Wisconsin Tobacco Quitline",
              link: "http://www.ctri.wisc.edu/quitline.html"
            }
          ]
        },

        {
          state: "Wyoming",
          links: [
            {
              label:
                "Wyoming Department of Health Tobacco Prevention Program",
              link:
                "https://health.wyo.gov/publichealth/prevention/tobacco-prevention/wqtp/"
            }
          ]
        }
      ],
      resources_quit: [
        {
          link: "",
          label: <a href="tel:8007848669">Call 1-800-QUIT-NOW (800-784-8669)</a>
        },
        {
          link: "http://smokefree.gov/",
          label: "Smokefree.gov"
        },
        {
          link: "http://betobaccofree.hhs.gov/quit-now/index.html",
          label: "BeTobaccoFree.gov"
        },
        {
          link:
            "http://www.cancer.org/healthy/stayawayfromtobacco/guidetoquittingsmoking/index",
          label: "American Cancer Society"
        }
      ],
      resources_instructions:
        "Choose a state from the drop down menu for resources that are specifically available there.",
      radon_instructions:
        "Radon is another preventable risk factor for lung cancer. Learn how you can reduce your risk at:",
      resources_radon: [
        {
          link:
            "https://www.epa.gov/sites/production/files/2016-12/documents/2016_a_citizens_guide_to_radon.pdf",
          label:
            "A Citizen's Guide to Radon compiled by the Environmental Protection Agency"
        },
        {
          link:
            "http://www.cdc.gov/nceh/radiation/brochure/profile_radon.htm",
          label: "Centers for Disease Control and Prevention"
        }
      ]
    },
    packPage: {
      title: "Pack year calculator",
      instruction: {
        normal:
          "A pack-year is used to describe how many cigarettes you have smoked in your lifetime, with a pack equal to 20 cigarettes. If you have smoked a pack a day for the last 20 years, or two packs a day for the last 10 years, you have 20 pack-years. In other words, pack-years is a way to measure smoking exposure, taking into account how long you have smoked, and how much you have smoked. Currently, having 20 pack-years or more is one of the criteria that needs to be met to be recommended for screening."
      },

      sub_title:
        "PLEASE ANSWER THESE TWO QUESTIONS TO WORK OUT YOUR PACK-YEARS.",
      submit_button_label: "Calculate",
      calculation_result_template:
        "Your smoking exposure is x_number pack-years.", // x_number will be replaced with calculation result, don't change it.
      pack_questions: {
        formYears: {
          label: "1. For how many years have you smoked?",
          placeholder: "how many years?"
        },
        formAmount: {
          label:
            "2. On average, how many cigarettes did you smoke per day? There are 20 cigarettes in a pack.",
          placeholder: "cigarettes per day?"
        }
      }
    },
    calculatePage: {
      banner_image_source: "/image/areachart2.png",
      banner_title: "Your Lung Cancer Risk",
      title:
        "If you smoke, the best way to lower your risk of lung cancer is to quit.",

      instruction: {
        normal: (
          <div>
            <p>
              Fill in the information below to find out whether you are in the
              group where screening is recommended by the&nbsp;
              <a
                target="_blank"
                href="https://www.uspreventiveservicestaskforce.org/Page/Document/RecommendationStatementFinal/lung-cancer-screening"
                rel="noopener noreferrer"
              >
                US Preventive Services Task Force
              </a>
              .&nbsp; The calculator will also indicate how much you stand to
              benefit from getting screened. This will help you better
              determine whether your potential benefit from screening
              outweighs the harms.
            </p>
          </div>
        ),
        strong_text_list: ["* INDICATES REQUIRED FIELDS"]
      },
      submit_button_label: "Submit",
      default_select_label: " -- select -- ",
      error_input_guidance:
        "Hi, one or more of the things you filled in may have a typo. Please scroll down and check.",
      tall_question: (
        <div>
          9. How tall are you? &nbsp;
          <a
            target="_blank"
            // href="https://www.google.com/search?q=convert+centimeters+to+feet+and+inches&ie=utf-8&oe=utf-8"
            href="https://share.streamlit.io/should-i-screen/unit-converter/main?lan=en"
            rel="noopener noreferrer"
          >
            Help me convert centimeters to feet and inches.
          </a>
        </div>
      ),
      risk_questions: {
        formOld: {
          label: "1. How old are you?* ",
          placeholder: "",
          help_text:
            "Question 1 is a required field, and the valid age range for this calculator is from 18 to 100 years old."
        },
        formCurrentStatus: {
          label: "2. What is your current smoking status?*",
          placeholder: "",
          options: [
            { label: "Smoker", value: 1 },
            { label: "Former Smoker", value: 0 },
            { label: "Never Smoker", value: 2 }
          ],
          help_text: "Question 2 is a required field."
        },
        formAgeLastSmoke: {
          label: "2.1. At what age did you quit smoking for the last time?*",
          placeholder: "",
          options: [],
          help_text:
            "You have indicated that you are former smoker. Please ensure that you have filled in a number that is less than your current age in Question 1."
        },
        formYearsSmoking: {
          label: "3. For how many years total have you smoked cigarettes?*",
          placeholder: "",
          options: [],
          help_text:
            "Question 3 is a required field. Please ensure that this value is less than your current age, and that it is a number."
        },
        formCigarettesPerDay: {
          label:
            "4. On average, how many cigarettes do/did you smoke per day?* There are 20 cigarettes in a pack.",
          placeholder: "",
          options: [],
          help_text:
            "Question 4 is a required field. Please ensure that you have filled in a number between 0 and 200."
        },
        formGender: {
          label: "5. What is your gender?",
          placeholder: "",
          options: [
            { label: "Male", value: "male" },
            { label: "Female", value: "female" }
          ]
        },
        formInsurance: {
          label: <div>6. Are you covered by Medicare? 🏥</div>,
          placeholder: "",
          options: [
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
            { label: "Don't know/Not applicable", value: "unknown" }
          ]
        },
        formHighestGradeCompleted: {
          label:
            "7. What is the highest grade or year of school you completed? ",
          placeholder: "",
          options: [
            { label: "Less than high school", value: 0 },
            { label: "High school graduate", value: 1 },
            { label: "Some training after high school", value: 2 },
            { label: "Some college", value: 3 },
            { label: "College graduate", value: 4 },
            { label: "Postgraduate or professional degree", value: 5 }
          ]
        },
        formRaceEthnicity: {
          label: "8. How would you describe your race/ ethnicity?",
          placeholder: "",
          options: [
            { label: "White", value: 0 },
            { label: "Black", value: 1 },
            { label: "Hispanic", value: 2 },
            { label: "Asian", value: 3 },
            { label: "American Indian or Alaskan Native", value: 4 },
            {
              label: "Native Hawaiian or Pacific Islanders",
              value: 5
            }
          ]
        },
        formTallFeet: {
          label: "",
          placeholder: "",
          right_side_label: "ft.",
          options: [
            { label: "3", value: 3 },
            { label: "4", value: 4 },
            { label: "5", value: 5 },
            { label: "6", value: 6 },
            { label: "7", value: 7 },
            { label: "8", value: 8 },
            { label: "9", value: 9 }
          ]
        },
        formTallInch: {
          label: "",
          placeholder: "",
          right_side_label: "in.",
          options: [
            { label: "0", value: 0 },
            { label: "1", value: 1 },
            { label: "2", value: 2 },
            { label: "3", value: 3 },
            { label: "4", value: 4 },
            { label: "5", value: 5 },
            { label: "6", value: 6 },
            { label: "7", value: 7 },
            { label: "8", value: 8 },
            { label: "9", value: 9 },
            { label: "10", value: 10 },
            { label: "11", value: 11 }
          ]
        },
        formWeight: {
          label: (
            <div>
              10. How much do you weigh? (lbs.) &nbsp;
              <a
                target="_blank"
                //href="https://www.google.com/search?q=convert+kilograms+to+pounds&ie=utf-8&oe=utf-8"
                href="https://share.streamlit.io/should-i-screen/unit-converter/main?lan=en"
                rel="noopener noreferrer"
              >
                Help me convert kilograms to pounds.
              </a>
            </div>
          ),
          placeholder: "",
          options: [],
          help_text:
            "Please ensure that you have entered a number between 45 and 1000 in Question 9. This is not a required field. Leaving it empty will assign a default value to our calculation."
        },
        formDoctorToldCancer: {
          label:
            "11. Have you ever been told by a doctor that you have cancer?",
          placeholder: "",
          options: [
            { label: "Yes", value: 1 },
            { label: "No", value: 0 }
          ]
        },
        formFamilyHistory: {
          label: "12. Does your family have a history of lung cancer?",
          placeholder: "",
          options: [
            { label: "Yes", value: 1 },
            { label: "No", value: 0 }
          ]
        },
        formDoctorToldCOPD: {
          label:
            "13. Have you ever been told by your doctor that you have chronic pulmonary disease also known as COPD (chronic bronchitis or emphysema)? ",
          options: [
            { label: "Yes", value: 1 },
            { label: "No", value: 0 }
          ]
        }
      }
    },
    feedbackPage: {
      title: "We appreciate your feedback!",
      submitted_message: "Thank you!",
      default_select_label: " -- select -- ",
      submit_button_label: "Submit",
      feedback_questions: {
        formGender: {
          label: "I am",
          placeholder: "",
          options: [
            { label: "Female", value: 0 },
            { label: "Male", value: 1 }
          ]
        },
        formSmokeStatus: {
          label: "I...",
          placeholder: "",
          options: [
            { label: "am a smoker.", value: 1 },
            { label: "used to smoke.", value: 0 },
            { label: "have never smoked.", value: 2 }
          ]
        },
        formAge: {
          label: "How old are you?",
          placeholder: "50",
          options: []
        },
        formCalculatorResult: {
          label: "According to what you entered in the calculator, you were:",
          placeholder: "",
          options: [
            {
              label: "Eligible to be screened",
              value: "Eligible to be screened"
            },
            {
              label: "Not eligible to be screened",
              value: "Not eligible to be screened"
            },
            {
              label: "Risk was too low to be calculated accurately",
              value: "Risk was too low to be calculated accurately"
            }
          ]
        },
        formInformationReceiver: {
          label: "I am looking for information for",
          placeholder: "",
          options: [
            { label: "Myself", value: "Myself" },
            { label: "My partner", value: "My partner" },
            { label: "My family member(s)", value: "My family member(s)" },
            { label: "My friend(s)", value: "My friend(s)" },
            { label: "My patient(s)", value: "My patient(s)" }
          ]
        },
        formFindAllInformation: {
          label:
            "I found all the information I was looking for about lung cancer screening.",
          placeholder: "",
          options: [
            { label: "Strongly Disagree", value: -2 },
            { label: "Disagree", value: -1 },
            { label: "Neutral", value: 0 },
            { label: "Agree", value: 1 },
            { label: "Strongly Agree", value: 2 }
          ]
        },
        formHelpConversationDoctor: {
          label:
            "The information I found helped me talk to my doctor about lung cancer screening.",
          placeholder: "",
          options: [
            { label: "Strongly Disagree", value: -2 },
            { label: "Disagree", value: -1 },
            { label: "Neutral", value: 0 },
            { label: "Agree", value: 1 },
            { label: "Strongly Agree", value: 2 }
          ]
        },
        formHelpDecision: {
          label:
            "The website helped me in my decision about lung cancer screening.",
          placeholder: "",
          options: [
            { label: "Strongly Disagree", value: -2 },
            { label: "Disagree", value: -1 },
            { label: "Neutral", value: 0 },
            { label: "Agree", value: 1 },
            { label: "Strongly Agree", value: 2 }
          ]
        },
        formInterestReason: {
          label: "I am interested in lung cancer screening because...",
          placeholder: "E.g., I have a family history of cancer."
        },
        formComment: {
          label:
            "Please feel free to submit any other comments that you think will help us improve the website:",
          placeholder: ""
        }
      }
    },
    resultPage: {
      print_button_label: "Print",
      title: "Lung Cancer Screening:",
      sub_title: "Should I do it?",
      risk_spectrum: {
        risk_statement: "Your lung cancer risk:",
        low_risk_label: <span>&#60; 1%: low risk</span>,
        intermediate_risk_label: <span>1-2%: intermediate risk</span>,
        high_risk_label: <span>&#62; 2%: high risk</span>
      },
      risk_confirmation:{
        label: "According to the chart, my risk of developing lung cancer is:",
        placeholder: "",
        options: [
          { label: "High", value: "High" },
          { label: "Intermediate", value: "Intermediate" },
          { label: "Low", value: "Low" },
          { label: "Don't know", value: "Don't know" }
        ]
      },
      comparison_chart_info_list:[
        {
          chartTitle:"Not screened",
          legendTitle: {
            "1000": "Out of 1000 people like you",
            "100": "Out of 100 people like you"
          },
          legendMap:{
            "die": "die from lung cancer",
            "not die": "did not die from lung cancer"
          }
        },
        {
          chartTitle:"Screened",
          legendTitle: {
            "1000": "Out of 1000 people like you",
            "100": "Out of 100 people like you"
          },
          legendMap:{
            "die": "die from lung cancer",
            "fewer die": {
              "full": "fewer died from lung cancer due to screening",
              "list": ["fewer died from lung cancer due ",  "to screening"]
            },
            "not die": "did not die from lung cancer"
          }
        },
      ],
      benefit_confirmation:{
        //label: "After seeing the chart, I consider my benefits of getting screened being:",
        label: "Quick check-in: based on the information above, I consider the benefits of screening, relative to my risk of developing lung cancer in the next 6 years, to be ",
        placeholder: "",
        options: [
          { label: "High enough", value: "High enough" },
          //{ label: "Intermediate", value: "Intermediate" },
          { label: "Too low", value: "Too low" },
          { label: "Don't know", value: "Don't know" }
        ]
      },
      risk_section: {
        title: "Risks",
        statement_list: [
          "120 in 1000 people who were screened found a lung nodule that was not cancer.",
          "13 in 1000 had an invasive procedure, such as biopsy or surgery, due to a lung nodule that was not cancer.",
          "Fewer than 1 in 1000 had a major complication from invasive procedures.",
          "Fewer than 10% of the lung cancer detected by screening are believed to be overdiagnosed*."
        ],
        foot_note: "* These overdiagnosed cases of lung cancer are ones that may not have caused harm had they not been found, and a patient may not have felt any symptoms. However, by virtue of finding this kind of cancer, a patient may receive treatment that is unnecessary, with potential for complications. Note that once a cancer is found, it is not possible to know if it had been an overdiagnosis or not."
      },
      benefit_section: {
        title: "Benefits",
        statement_end:
          "fewer people like you will die from lung cancer among those who were screened compared to those who were not screened."
      },
      instructions: {
        beforeSubmission: [
          "By now, you should have a sense of what screening is and what it can and can't do. It will be helpful for your doctor to understand how you feel about the pros and cons of screening. Please check which sentence below best describes your answer to the following question. There is no right or wrong answer!",
          "IN YOUR VIEW, DOES YOUR PERSONAL BENEFIT FROM SCREENING JUSTIFY GETTING SCREENED? CHOOSE ONE OPTION BELOW."
        ],
        afterSubmission: [
          "Remember to talk to your doctor about your view on lung cancer screening!"
        ]
      },
      default_select_label: " -- select -- ",
      submit_button_label: "Submit",
      click_for_pdf_msg:
        "Click on the image for a printer-friendly version of the results.",
      getEligibilityStatement: function(sex, risk, eligibility, insurance, old, packYears, neverSmoked=false) {
        // sex: male, female
        // risk: 0.0 ~
        // eligibility: yes, no
        // insurance: yes, no, unknown
        // Old = -1;
        // yearsSmoking = -1;
        // cigarettesPerDay = -1;

        var result = "Default Eligibility";

        if(neverSmoked){
          result = <div>Lung cancer CT screening is not recommended for people who have never smoked.</div>;
          return result;
        }

        switch (eligibility) {
          case "yes":
            result = "";

            if (insurance == "yes") {
              // M
              result = (
                <div>
                  Given your age and smoking history, you are{" "}
                  <strong>eligible</strong> for screening according to the US
                  Preventive Services Task Force criteria.
                </div>
              );
            } else {
              if( old > 77){
                result = (
                  <div>
                    Given your age and smoking history, you are{" "}
                    <strong>eligible</strong> for screening according to the US
                    Preventive Services Task Force criteria. However, Medicare
                    only covers lung cancer screening up to age 77. Please see
                    our &nbsp;
                    <Link
                      to={"/English/coverage"}
                      style={{ width: "100%" }}
                      data-on="click"
                      data-event-category="CoveragePage"
                      data-event-action={"/English/coverage"}
                    >
                      insurance coverage page&nbsp;
                    </Link>{" "}
                    or contact your insurance provider to see if there are other
                    options that might cover the cost of lung cancer screening.
                  </div>
                );
              }
              else{
                result = (
                  <div>
                    Given your age and smoking history, you are{" "}
                    <strong>eligible</strong> for screening according to the US
                    Preventive Services Task Force criteria.
                  </div>
                );
              }
              
            }
            break;
          case "no":
            result = (<p>
              Given your age and smoking history, you are{" "}
              <strong>not eligible*</strong> for screening according to the
              US Preventive Services Task Force criteria. 
            </p>
            );
            break;
          default:
            result = "";
            break;
        }

        return result;
      },
      getChanceStatement(sex, risk, eligibility, insurance, old, packYears) {
        // sex: male, female
        // risk: 0.0 ~
        // eligibility: yes, no
        // medicare: yes, no, unknown
        var result = "Default Chance";

        console.log(`getChanceStatement(), old: ${old}, packYears: ${packYears}`);

        let  tailorMessage = "";

        let oldInRange = old >= 50 && old <=54;
        let packYearsInRange = packYears >= 20 && packYears <=29;
        //As you {tailorMessage}, you are newly eligible for lung cancer screening under the most recent US Preventive Services Task Force recommendations. You may not be covered by insurance yet, so please talk to your insurance provider for more information about when you will be covered.
        if(oldInRange && packYearsInRange){
          tailorMessage = `As you are 50-54 years old and have a smoking history of 20-29 pack-years, you are newly eligible for lung cancer screening under the most recent US Preventive Services Task Force recommendations. You may not be covered by insurance yet, so please talk to your insurance provider for more information about when you will be covered.`;
        }
        else if(!oldInRange && packYearsInRange){
          tailorMessage = `As you have a smoking history of 20-29 pack-years, you are newly eligible for lung cancer screening under the most recent US Preventive Services Task Force recommendations. You may not be covered by insurance yet, so please talk to your insurance provider for more information about when you will be covered.`;
        }
        else if(oldInRange && !packYearsInRange){
          tailorMessage = `As you are 50-54 years old, you are newly eligible for lung cancer screening under the most recent US Preventive Services Task Force recommendations. You may not be covered by insurance yet, so please talk to your insurance provider for more information about when you will be covered.`;
        }

        if (risk == 0 ) {
          // smoker or not
          result = (
            <div>
              The chance of you developing lung cancer in the next 6 years is
              too low to be measured accurately. Talk to your doctor about
              screening if you are concerned about your risk for lung cancer
              as they will understand your situation best.
            </div>
          );
        } 
        else if (eligibility == "no") {
          if(risk >= 1.5){
            result = (<Fragment>
              <p>
                *However, the chance of you developing lung cancer in the next
                6 years is {risk}%, which is where we believe the benefits of screening are large enough to consider
                CT screening as an option. You should consider talking to your
                doctor about whether lung cancer screening might still be a
                good choice for you.
                
              </p>
              </Fragment>
            );
          }
          else{
            result = (
              <div>
                The chance of you developing lung cancer in the next 6 years
                is {risk}%. Talk to your doctor about the option to screen or
                not to screen as they will understand your situation best.
              </div>
            );
          }
          
        } else if (eligibility == "yes")  {
          if(risk < 1.5){
            result = (<Fragment>
              <p>
                {tailorMessage}
              </p>
              <p>
                *However, the chance of you developing lung cancer in the next
                6 years is {risk}%, which is where we believe the benefits of screening are not large enough to consider
                CT screening as an option. You should consider talking to your
                doctor about whether lung cancer screening might still be a
                good choice for you.
                
              </p>

              </Fragment>
            );
          }
          else{
            result = (
              <div>
                <p>
                The chance of you developing lung cancer in the next 6 years
                is {risk}%. Talk to your doctor about the option to screen or
                not to screen as they will understand your situation best.
                </p>          
                <p> 
                  {tailorMessage}
              </p>
              </div>
            );
          }
        }
        return result;
      },
      getExplanationStatement(sex, risk, eligibility, insurance) {
        // sex: male, female
        // risk: 0.0 ~
        // eligibility: yes, no
        // insurance: yes, no, unknown
        var result = "Default Explanation";

        // Math.round()

        // deal with it.

        // method 2: encapsulate into a function in  MyCalcuataion
        result = (
          <div>
            Compared to other people like you, there will be{" "}
            {MyCalculation.calculateSavedByScreeningFromRisk(risk) +
              " "}
            fewer deaths out of 1000 in the next 6 years if you get screened.
          </div>
        );

        // method 1: calculate directly
        /*
        result = (
          <div>
            Compared to other people like you, there will be{" "}
            {Math.round(Math.round(risk * 10) - Math.round(risk * 10 * 0.8)) +
              " "}
            fewer deaths out of 1000 in the next 6 years if you get screened.
          </div>
        );
        */

        return result;
      },
      getBenefitTitle(sex, risk, eligibility, insurance) {
        // sex: male, female
        // risk: 0.0 ~
        // eligibility: yes, no
        // insurance: yes, no, unknown

        // we only need risk

        var result = "Default Benefit";

        result =
          MyCalculation.calculateSavedByScreeningFromRisk(risk)  +
          " in 1000";

        return result;
      },
      reflection_questions: {
        formPersonalBenefit: {
          label: "",
          placeholder: "",
          options: [
            {
              label:
                "Yes. My benefit from screening is enough to justify getting screened.",
              value:
                "Yes. My benefit from screening is enough to justify getting screened."
            },
            {
              label:
                "Maybe. My benefit from screening is probably enough to justify getting screened but I am not sure.",
              value:
                "Maybe. My benefit from screening is probably enough to justify getting screened but I am not sure."
            },
            {
              label:
                "Maybe not. My benefit from screening is small and I'm not sure it justifies getting screened.",
              value:
                "Maybe not. My benefit from screening is small and I'm not sure it justifies getting screened."
            },
            {
              label:
                "No. My benefit from screening is too small to justify getting screened.",
              value:
                "No. My benefit from screening is too small to justify getting screened."
            }
          ]
        },
        formRecommended: {
          label: "I was:",
          placeholder: "",
          options: [
            {
              label: "Recommended to be screened",
              value: "Recommended to be screened"
            },
            {
              label: "Not recommended to be screened",
              value: "Not recommended to be screened"
            }
          ]
        },
        formRisk: {
          label: "My risk was",
          placeholder: "1%"
        },
        formReason: {
          label: "My reasons are:",
          placeholder: ""
        }
      }
    },
    coveragePage: {
      banner_image_source: "/image/bannerImage_coveragePage.png",
      banner_title: "Insurance coverage of lung cancer screening",
      title:
        "Click on the option that applies to you to see if lung cancer screening is covered by your health insurance.",
      sub_title: "Note: As US Preventive Services Task Force recommendations were updated on 9 March 2021, insurance coverage has yet to reflect these changes. Please contact your insurance providers to confirm whether lung cancer screening would be covered for you if you are: i) between 50 and 54 years old with a smoking history of more than 20 pack-years; or ii) between 55 and 80 years old with a smoking history of 20-29 pack-years.",
      condition_list: [
        {
          title: "Employer-sponsored plan",
          introtext:
            "This is insurance that is provided for you through your employer, union or other professional association. You are eligible to be covered for the initial lung cancer screening if you:",
          criteria_list: [
            "are between 50 and 80 years old",
            <div>
              have a smoking history of 20 pack-years (Click&nbsp;
              <Link
                to={"/English/pack-year-calculator"}
                style={{ width: "100%", textDecoration: "none" }}
                data-on="click"
                data-event-category="CoveragePage"
                data-event-action={"/English/pack-year-calculator"}
              >
                here&nbsp;
              </Link>
              to work out your pack-years)
            </div>,
            "have smoked within the last 15 years"
          ],

          note:
            "If your plan is 'grandfathered' under the Affordable Care Act, it is not required to cover lung cancer screening. Check the documents of your plan and verify with your insurer about this.",
          question_answer_list: [
            {
              question: "Should there be cost-sharing?",
              answer:
                "No. You should not be charged a copay, a co-insurance or deductible for screening if you go to a provider who is in your network. "
            },
            {
              question: "Will there be other costs?",
              answer:
                "There might be. Be sure to check with your insurance company what they are covering for the procedure."
            }
          ]
        },
        {
          title: "State insurance marketplace plan",
          introtext:
            "This is a plan that you enrolled in via healthcare.gov or a marketplace/exchange website set up by your state. You are eligible to be covered for the initial lung cancer screening if you:",
          criteria_list: [
            "are between 50 and 80 years old",
            <div>
              have a smoking history of 20 pack-years (Click&nbsp;
              <Link
                to={"/English/pack-year-calculator"}
                style={{ width: "100%", textDecoration: "none" }}
                data-on="click"
                data-event-category="CoveragePage"
                data-event-action={"/English/pack-year-calculator"}
              >
                here
              </Link>{" "}
              to work out your pack-years)
            </div>,
            "have smoked within the last 15 years"
          ],
          question_answer_list: [
            {
              question: "Should there be cost-sharing?",
              answer:
                "No. You should not be charged a copay, a co-insurance or deductible for screening if you go to a provider who is in your network. "
            },
            {
              question: "Will there be other costs?",
              answer:
                "There might be. Be sure to check with your insurance company what they are covering for the procedure."
            }
          ]
        },
        {
          title: "Medicare",
          introtext:
            "You are eligible to be covered for the initial lung cancer screening if you:",
          criteria_list: [
            "are between 50 and 77 years old",
            <div>
              have a smoking history of 20 pack-years (Click&nbsp;
              <Link
                to={"/English/pack-year-calculator"}
                style={{ width: "100%", textDecoration: "none" }}
                data-on="click"
                data-event-category="CoveragePage"
                data-event-action={"/English/pack-year-calculator"}
              >
                here
              </Link>{" "}
              to work out your pack-years)
            </div>,
            "have smoked within the last 15 years",
            "have no signs or symptoms (e.g., pain, weight loss, persistent coughing) of lung cancer"
          ],
          question_answer_list: [
            {
              question: "Should there be cost-sharing?",
              answer:
                "No. You should not be charged a copay, a co-insurance or deductible for screening if you go to a provider who is in your network. "
            },
            {
              question: "Will there be other costs?",
              answer:
                "There might be. Be sure to check with your insurance company what they are covering for the procedure."
            }
          ]
        },
        {
          title: "Medicaid",
          introtext:
            "Coverage of lung cancer screening in Medicaid varies by state. We suggest that you contact your Medicaid provider and check if your plan covers lung cancer screening.",
          criteria_list: [],
          note: "",
          question_answer_list: []
        },
        {
          title: "Individual plan",
          introtext:
            "This is insurance that you buy directly from an insurer. You are eligible to be covered for the initial lung cancer screening if you:",
          criteria_list: [
            "are between 50 and 80 years old",
            <div>
              have a smoking history of 20 pack-years (Click&nbsp;
              <Link
                to={"/English/pack-year-calculator"}
                style={{ width: "100%", textDecoration: "none" }}
                data-on="click"
                data-event-category="CoveragePage"
                data-event-action={"/English/pack-year-calculator"}
              >
                here
              </Link>{" "}
              to work out your pack-years)
            </div>,
            "have smoked within the last 15 years"
          ],

          note:
            "If your plan is 'grandfathered' under the Affordable Care Act, it is not required to cover lung cancer screening. Check the documents of your plan and verify with your insurer about this.",
          question_answer_list: [
            {
              question: "Should there be cost-sharing?",
              answer:
                "No. You should not be charged a copay, a co-insurance or deductible for screening if you go to a provider who is in your network. "
            },
            {
              question: "Will there be other costs?",
              answer:
                "There might be. Be sure to check with your insurance company what they are covering for the procedure."
            }
          ]
        }
      ],
      citation_text: (
        <div>
          <p>
            Click&nbsp;
            <a
              target="_blank"
              href="https://www.lung.org/lung-health-diseases/lung-disease-lookup/lung-cancer/saved-by-the-scan/resources/is-lung-cancer-screening-right"
              rel="noopener noreferrer"
            >
              here&nbsp; 
            </a>
            for the most up-to-date insurance coverage information from the American Lung Association.
          </p>
        </div>
      )
    },
    savedPage: {
      title: "Here are the materials you saved",
      item_unit: "snippet(s)",
      print_button_label: "Print"
    },
    publicationPage: {
      title: "Research Publications",
      publication_list: [
        {
          category: "journal",
          text: (
            <span>
              Yan Kwan Lau, Harihar Bhattarai, Tanner J Caverly, Pei-Yao Hung, 
              Evelyn Jimenez-Mendoza, Minal R Patel, Michele L Coté, 
              Douglas A Arenberg, Rafael Meza (2021).
              Lung Cancer Screening Knowledge, Perceptions, and Decision Making 
              Among African Americans in Detroit, Michigan.{" "}
              <i>American Journal of Preventive Medicine (AJPM)</i>, Volume
              60, Issue 1, Pages e1-e8
            </span>
          ),
          year: 2021,
          description:
            "We tested whether shouldiscreen.com helped improve people's knowledge of lung cancer risks and lung cancer screening, and decreased their decisional conflict in eastern Detroit, Michigan.",
          links: [
            {
              label: "Link",
              link: "https://pubmed.ncbi.nlm.nih.gov/33341184/"
            }
          ]
        },
        {
          category: "conference",
          text: (
            <span>
              Pei-Yao Hung, Yan Kwan Lau, Mark S. Ackerman, Rafael Meza
              (2019). Designing a Web-based Decision Aid for Individuals to
              Consider Lung Cancer Screening.{" "}
              <i>
                13th EAI International Conference on Pervasive Computing
                Technologies for Healthcare (PervasiveHealth)
              </i>
              , Trento, Italy, May 20-23. pp 51-60
            </span>
          ),
          year: 2019,
          description:
            "We conducted participatory design workshops and focus groups to provide design suggestions for decision aids and to guide the development of shouldiscreen.com.",
          links: [
            {
              label: "Link",
              link: "https://doi.org/10.1145/3329189.3329210"
            }
          ]
        },
        {
          category: "journal",
          text: (
            <span>
              Yan Kwan Lau, Tanner J. Caverly, Pianpian Cao, Sarah T. Cherng,
              Mindy West, Charles Gaber, Douglas Arenberg, Rafael Meza (2015).
              Evaluation of a Personalized, Web-Based Decision Aid for Lung
              Cancer Screening.{" "}
              <i>American Journal of Preventive Medicine (AJPM)</i>, Volume
              49, Issue 6, Pages e125–e129
            </span>
          ),
          year: 2015,
          description:
            "We tested whether shouldiscreen.com helped improve people's knowledge of lung cancer risks and lung cancer screening, and decreased their decisional conflict.",
          links: [
            {
              label: "Link",
              link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5544524/"
            }
          ]
        },
        {
          category: "journal",
          text: (
            <span>
              Yan Kwan Lau, Tanner J Caverly, Sarah T Cherng, Pianpian Cao,
              Mindy West, Douglas Arenberg, and Rafael Meza (2014).
              Development and Validation of a Personalized, Web-Based Decision
              Aid for Lung Cancer Screening Using Mixed Methods: A Study
              Protocol. <i>JMIR Research Protocol</i>
            </span>
          ),
          year: 2014,
          description:
            "This is a protocol that describes the various phases of research plans and development for shouldiscreen.com.",
          links: [
            {
              label: "Link",
              link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4376198/"
            }
          ]
        }
      ]
    },
    teamPage: {
      title: "The developers of shouldiscreen.com",

      investigator_title: "PRINCIPAL INVESTIGATORS",

      investigator_list: [
        {
          name: "Rafael Meza, PhD",
          affiliation: [
            "Department of Epidemiology, School of Public Health, University of Michigan"
          ],
          link: "https://sph.umich.edu/faculty-profiles/meza-rafael.html"
        },
        {
          name: "Douglas Arenberg, MD",
          affiliation: [
            "Department of Internal Medicine, University of Michigan"
          ],
          link: "https://uofmhealth.org/profile/286/douglas-allen-arenberg-md"
        },
        {
          name: "Yan Kwan Lau, PhD, MPH",
          affiliation: [
            "Department of Epidemiology, School of Public Health, University of Michigan"
          ],
          link: ""
        },
        {
          name: "Tanner Caverly, MD, MPH ",
          affiliation: [
            "Center for Clinical Management Research, Veterans Affairs, Ann Arbor Healthcare Systems; Department of Internal Medicine, University of Michigan"
          ],
          link: "http://medicine.umich.edu/dept/lhs/tanner-caverly-md-mph"
        }
      ],

      research_development_title: "RESEARCH AND DEVELOPMENT TEAM",

      research_development_list: [
        {
          name: "Pianpian Cao, MPH",
          affiliation: [
            "Department of Epidemiology, School of Public Health, University of Michigan"
          ],
          link: "https://sph.umich.edu/epid/phdstudents/cao_pianpian.html"
        },
        {
          name: "Sarah Cherng, PhD, MPH",
          affiliation: [
            "Department of Epidemiology, School of Public Health, University of Michigan"
          ],
          link: "http://www.sarahcherng.com/"
        },
        {
          name: "Pei-Yao Hung, PhD, MSI",
          affiliation: ["School of Information, University of Michigan"],
          link: "https://peiyaoh.github.io/"
        },
        {
          name: "Mindy West, MPH",
          affiliation: [
            "Department of Epidemiology, School of Public Health, University of Michigan"
          ],
          link: ""
        }
      ],

      press_title: "PRESS RELEASES",

      press_resources_list: [
        {
          link:
            "https://www.healthcanal.com/cancers/lung-cancer/68111-tool-helps-patients-providers-decide-if-lung-cancer-screening-is-warranted.html",
          label:
            "Tool helps patients decide if lung cancer screening is warranted",
          source_label: "Health Canal"
        },
        {
          link:
            "http://www.reuters.com/article/us-health-screening-lung-cancer-idUSKCN0SF2LQ20151021#f3dQjQZuWa652jvR.97",
          label:
            "Web module helps patients decide about lung cancer screening",
          source_label: "Reuters"
        },
        {
          link:
            "https://www.nytimes.com/2015/05/12/health/on-medicare-and-assessing-the-value-of-lung-cancer-screening.html",
          label:
            "On Medicare and assessing the value of lung cancer screening",
          source_label: "New York Times"
        },
        {
          link:
            "https://news.umich.edu/tool-helps-patients-providers-decide-if-lung-cancer-screening-is-warranted/",
          label:
            "Tool helps patients, providers decide if lung cancer screening is warranted",
          source_label: "University of Michigan News"
         }
      ],
      founding_title: "FUNDING SUPPORT",
      founding_content:
        "The development of this tool was supported by the Elizabeth A Crary Fund of the University of Michigan Comprehensive Cancer Center (2014-2015), and the University of Michigan Rogel Cancer Center, Cancer Control and Population Sciences Research Program: Outreach and Health Disparities Grant."
    },
    historyPage: {
      title: "The development history of shouldiscreen.com",
      description: <Fragment>
        <p>Shouldiscreen.com is a lung cancer screening decision aid that is designed, evaluated, and re-designed continually based on existing literature, multidisciplinary research effort, health experts' suggestions, and feedback from users.</p>
        <p>Our goal is to continually improve it by keeping it up to date with the current understanding of lung cancer screening and refine it with human-centered methodology so that people can easily obtain a better understanding of relevant scientific knowledge and best practices.</p>
        <p>Here we outline a list of milestones and changes in the development of shouldicreen.com.</p>
      </Fragment>,
      eventList:[
        // marker_color:  color from bulma.css: is-primary (green), is-link, is-info, is-success, is-warning, is-danger
        // markder_type: "", is-image, is-icon
        {
          label: "June 2014",
          description: "First version of shouldiscreen created for feedback from potential users and health risk communication experts.",
          timestamp: "2014-06-01T00:00:00",
          marker_color: "is-warning",
          marker_type: "is-icon",
          // fontawesome
          marker_icon_type: "fas fa-play"
        },
        {
          label: "January 2015",
          description: "Version 1 of shouldiscreen.com officially deployed",
          timestamp: "2015-01-01T00:00:00",
          marker_color: "is-success",
          marker_type: "is-icon",
          // fontawesome
          marker_icon_type: "fa fa-flag"
        },
        {
          label: "September 2017",
          description: "Enable bilingual support with Spanish content.",
          timestamp: "2017-09-01T00:00:00",
          marker_color: "is-danger",
          marker_type: "is-icon",
          // fontawesome
          marker_icon_type: "fa fa-book"
        },
        {
          label: "July 2018",
          description: <Fragment>Added insurance coverage based on observations and feedback from participatory design and focus group studies (see <a
            target="_blank"
            href="https://dl.acm.org/doi/10.1145/3329189.3329210"
            rel="noopener noreferrer"
          >
            link
          </a>).</Fragment>,
          timestamp: "2018-07-01T00:00:00",
          marker_color: "is-link"
        },
        {
          label: "September 2018",
          description: <Fragment>Added a save-for-later feature for people to collect relevant content based on a conversation with clinicians who conduct shared decision-making with patients.</Fragment>,
          timestamp: "2018-09-01T00:00:00",
          marker_color: "is-link"
        },
        {
          label: "September 2018",
          description: <Fragment>Added a pop-up menu for people to provide feedback (helpful/not helpful) on content.</Fragment>,
          timestamp: "2018-09-15T00:00:00",
          marker_color: "is-primary"
        },
        {
          label: "December 2019",
          description: <Fragment>Added a question-based navigation menu based on observations from participatory design and focus group studies (see <a
            target="_blank"
            href="https://dl.acm.org/doi/10.1145/3329189.3329210"
            rel="noopener noreferrer"
          >
            link
          </a>).</Fragment>,
          timestamp: "2019-12-01T00:00:00",
          marker_color: "is-link"
        },
        {
          label: "May 2019",
          description: <Fragment>Presented our participatory design and focus group research on the design of shouldiscreen.com at the <i>13th EAI International Conference on Pervasive Computing Technologies for Healthcare</i> (PervasiveHealth 2019)(see <a
            target="_blank"
            href="https://dl.acm.org/doi/10.1145/3329189.3329210"
            rel="noopener noreferrer"
          >
            link
          </a>).</Fragment>,
          timestamp: "2019-05-21T00:00:00",
          marker_color: "is-warning"
        },
        {
          label: "December 2019",
          description: <Fragment>Updated screening eligibility criteria with Medicare.</Fragment>,
          timestamp: "2019-12-12T00:00:00",
          marker_color: "is-primary"
        },
        {
          label: "January 2020",
          description: <Fragment>Enabled tailoring of messages based on calculated lung cancer risk and biographical information.</Fragment>,
          timestamp: "2020-01-01T00:00:00",
          marker_color: "is-primary"
        },
        {
          label: "August 2020",
          description: <Fragment>Added multiple visualization options to help people understand screening benefits and risks.</Fragment>,
          timestamp: "2020-08-22T00:00:00",
          marker_color: "is-primary"
        },
        {
          label: "November 2020",
          description: <Fragment>Revised content to reflect findings in USPSTF evidence review (2020) with regards to harms from false positives when using LungRADS (see <a
            target="_blank"
            href="https://www.uspreventiveservicestaskforce.org/uspstf/document/draft-evidence-review/lung-cancer-screening1"
            rel="noopener noreferrer"
          >
            link
          </a>).</Fragment>,
          timestamp: "2020-11-21T00:00:00",
          marker_color: "is-primary"
        },
        {
          label: "March 2021",
          description: <Fragment>Revised content to reflect USPSTF recommendations (2021) to lower age and pack-year thresholds for lung cancer screening (see <a
            target="_blank"
            href="https://www.uspreventiveservicestaskforce.org/uspstf/recommendation/lung-cancer-screening"
            rel="noopener noreferrer"
          >
            link
          </a>).</Fragment>,
          timestamp: "2021-03-09T00:00:00",
          marker_color: "is-danger",
          marker_type: "is-icon",
          // fontawesome
          marker_icon_type: "fas fa-info-circle"
        },
        {
          label: "January 2022",
          description: "Enable multilingual support with Chinese content.",
          timestamp: "2022-01-29T00:00:00",
          marker_color: "is-danger",
          marker_type: "is-icon",
          // fontawesome
          marker_icon_type: "fa fa-flag"
        },
        /*
        {
          label: "February 2016",
          description: "Timeline content - Can include any HTML element",
          timestamp: "2016-02-01T00:00:00",
          marker_color: "is-warning",
          marker_type: "is-image",
          marker_image_size: "is-32x32",
          marker_image_source: "https://bulma.io/images/placeholders/32x32.png"
        },
        */
        
      ]
    },
    noPage: {
      title: "Should I Screen: not found",
      not_found_message:
        "You have reached this place by accident, or there is an issue on our site. Please click the following link to go back to the homepage.",
      take_me_home_label: "Take me to the home page.",
      take_me_home_link: "/English/home"
    },
    footerComponent: {
      development_team_content: (
        <p>
          Created by &nbsp;
          <Link
            to={"/English/development-team"}
            className="my-link"
            data-on="click"
            data-event-category="Navigation"
            data-event-action={"team"}
          >
            University of Michigan
          </Link>
          &nbsp; using the risk prediction model developed by Tammemägi et al.
          (2013).&nbsp;
          <a
            target="_blank"
            href="https://www.nejm.org/doi/full/10.1056/NEJMoa1211776"
            rel="noopener noreferrer"
            className="my-link"
          >
            Selection criteria for lung-cancer screening
          </a>
          .&nbsp;<em>New England Journal of Medicine</em>, 368(8): 728-736,
          2013.
        </p>
      ),
      basis_content: (
        <p>
          See{" "}
          <Link
            to={"/English/publications"}
            data-on="click"
            data-event-category="Navigation"
            data-event-action={"publications"}
            className="my-link"
          >
            publications
          </Link>{" "}
          for peer-reviewed articles regarding the design, development, and
          validation of this decision aid.
        </p>
      ),

      feedback_content: (
        <p>
          Your feedback is valuable to us. Please&nbsp;
          <Link to={"/English/feedback"} className="my-link">fill out this survey</Link>
          &nbsp;to help us improve the website.&nbsp;
        </p>
      ),

      disclaimer_content: (
        <p>
          <Link to={"/English/disclaimer"} className="my-link">Disclaimer</Link>. &nbsp;
          <em>
            Written content of this website are shared under &nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://creativecommons.org/licenses/by-nc-sa/4.0/"
              className="my-link"
            >
              CC BY-NC-SA 4.0
            </a>
            .&nbsp;
          </em>
          All images on this website are copyrighted material and all rights
          are reserved unless otherwise referenced on the page.
        </p>
      )
    },
    lastUpdateComponent: {
      message_head: "This page was last updated on ",
      pointer_to_history: <Fragment>
        See <Link
                to={"/English/development-history"}
                data-on="click"
                data-event-category="UpdateComponent"
                data-event-action={"/English/development-history"}
                className="my-link"
              >
                the history of changes
              </Link>.
      </Fragment>
    },
    headerComponent: {
      title: "About Lung Cancer & Screening",
      menu_title: " Menu",
      route_in_menu_limit: 8, //9
      route_order_list: [
        {
          route: "/English/home",
          label: "Home",
          title: "Should I Screen: Lung cancer screening decision aid",
          page: "HomePage",
          description: "Lung cancer screening uses low-dose computed tomography (LDCT) to find lung cancer at an early stage. Here is information provided by doctors to help you decide.",
          hashTagList: ["shouldiscreen", "lungcancerscreening", "health"]
        },
        {
          route: "/English/how-is-screening-done",
          label: "What is lung cancer screening",
          title: "What is lung cancer screening",
          page: "HowPage",
          hashTagList: ["shouldiscreen", "lungcancerscreening", "health"]
        },
        {
          route: "/English/coverage",
          label: "Insurance coverage",
          title: "Insurance coverage of lung cancer screening",
          page: "CoveragePage",
          hashTagList: ["shouldiscreen", "lungcancerscreening", "health"]
        },
        {
          route: "/English/benefits-and-harms-screening",
          label: "Benefits and harms of screening",
          title: "Benefits and harms of lung cancer screening",
          page: "BenefitHarmPage",
          hashTagList: ["shouldiscreen", "lungcancerscreening", "health"]
        },
        /*
        {
          route: "/English/compare-with-other-screening-tests",
          label: "Compared to other tests",
          title:
            "Comparison of lung cancer screening to other types of common cancer screening",
          page: "ComparePage",
          hashTagList: ["shouldiscreen", "lungcancerscreening", "health"]
        },
        */
        {
          route: "/English/causes-of-lung-cancer",
          label: "Causes of lung cancer",
          title: "Causes of lung cancer",
          page: "CausePage",
          hashTagList: ["shouldiscreen", "lungcancerscreening", "health"]
        },
        {
          route: "/English/pack-year-calculator",
          label: "Pack year calculator",
          title: "Calculate pack years",
          page: "PackPage",
          hashTagList: ["shouldiscreen", "lungcancerscreening", "health"]
        },
        {
          route: "/English/risk-reduction-lung-cancer",
          label: "Reducing risk of lung cancer",
          title: "Reducing risk of lung cancer",
          page: "RiskPage",
          hashTagList: ["shouldiscreen", "lungcancerscreening", "health"]
        },
        {
          route: "/English/lung-cancer-risk-calculator",
          label: "Calculate my lung cancer risk",
          title: "Calculate my lung cancer risk",
          page: "CalculatePage",
          hashTagList: ["shouldiscreen", "lungcancerscreening", "health"]
        },
        {
          route: "/English/result",
          label: "Result",
          title: "What is my lung cancer risk",
          page: "ResultPage",
          hashTagList: ["shouldiscreen", "lungcancerscreening", "health"]
        },
        {
          route: "/English/disclaimer",
          label: "Disclaimer",
          title: "Disclaimer",
          page: "DisclaimerPage",
          hashTagList: ["shouldiscreen", "lungcancerscreening", "health"]
        },
        {
          route: "/English/development-team",
          label: "Development Team",
          title: "Development Team",
          page: "TeamPage",
          hashTagList: ["shouldiscreen", "lungcancerscreening", "health"]
        },
        {
          route: "/English/development-history",
          label: "Development History",
          title: "Development History",
          page: "HistoryPage",
          hashTagList: ["shouldiscreen", "lungcancerscreening", "health"]
        },
        {
          route: "/English/feedback",
          label: "Feedback",
          title: "Feedback",
          page: "FeedbackPage",
          hashTagList: ["shouldiscreen", "lungcancerscreening", "health"]
        },
        {
          route: "/English/saved",
          label: "Saved",
          title: "Saved Content",
          page: "SavedPage",
          hashTagList: ["shouldiscreen", "lungcancerscreening", "health"]
        },
        {
          route: "/English/publications",
          label: "Publications",
          title: "Publications",
          page: "PublicationPage",
          hashTagList: ["shouldiscreen", "lungcancerscreening", "health"]
        }
      ]
    },
    savedComponent: {
      saved_label: "Saved",
      saved_url: "/English/saved"
    }
  }
}
